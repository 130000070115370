<template>
    <div id="botter-cxi-video-wrap">
        <div style="visibility: hidden" id="cxi-media-container">
            <div style="visibility: hidden" id="videolocal" v-show="video"></div>
            <div style="visibility: hidden" id="videoremote0"></div>
			<div id="audiobg" v-show="this.$store.state.cxi.audio"></div>
            <div class="botter-cxi--actions-wrap">
				<div class="botter-cxi--agent-avatar" v-if="media_type == 'audio'">
					<img  :src="$store.state.core.widgetParameters.agentDefaultAvatar || 'https://gamma-api.botter.live/uploads/2020-08-16/1597590733.png'" alt="">
				</div>
                <div class="botter-cxi--agent-info">
                    <div class="agent--name">{{ $store.state.cxi.agent_info.name }}</div>
                    <div class="conversation--time">{{ timeDuration }}</div>
                </div>
                <div class="botter-cxi--local-actions">
                    <button type="button" class="" @click="toggleVideo" v-if="media_type == 'video'">
                        <i v-if="video" class="mdi mdi-video"></i>
                        <i v-else class="mdi mdi-video-off"></i>
                    </button>
                    <button type="button" class="" @click="toggleMute">
                        <i v-if="audio" class="mdi mdi-microphone"></i>
                        <i v-else class="mdi mdi-microphone-off"></i>
                    </button>
                    <button type="button" class="botter-cxi--action-end" @click="endCall">
                        <i v-if="callStarted" class="mdi mdi-phone-hangup"></i>
                        <i v-else class="mdi mdi-phone-in-talk"></i>
                    </button>
					<button type="button" class="" @click="toggleChat">
						<span v-if="!$store.state.messages.chatActive && $store.state.cxi.unread_messages > 0" class="cxi_new_message">
							{{ $store.state.cxi.unread_messages }}
						</span>
                        <i class="mdi mdi-chat"></i>
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    props: {
        client: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            audio: true,
            video: true,
            callStarted: false,
			timeStartedCounter: 0,
			timeDuration: '00:00:00',
			timerInstance: null,
			modalActive: true
        }
    },
    mounted(){
		console.log('client_media', this.client)

    },
    methods: {
        toggleMute(){
            this.audio = !this.audio
            this.client.toggleMute()
        },
        toggleVideo(){
            this.video = !this.video
            this.client.toggleVideo()
        },
        endCall(){
            this.callStarted = false
            this.client.endcall()
			clearInterval(this.timerInstance)
			this.OpenChat()
        },
		toggleChat(){
			document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.toggle('botter-modal-btn-active')
			document.getElementsByClassName('botter-modal')[0].classList.toggle('botter-modal-active')
			this.modalActive = document.getElementsByClassName('botter-modal')[0].classList.contains('botter-modal-active')
			this.$store.dispatch('setChatActive', this.modalActive)
		},
		OpenChat(){
			document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.add('botter-modal-btn-active')
			document.getElementsByClassName('botter-modal')[0].classList.add('botter-modal-active')
			this.modalActive = true
			this.$store.dispatch('setChatActive', this.modalActive)
		},
		startTimeDuration(){
			this.callStarted = true
			if(window.innerWidth < 1024){
				console.log(window.innerWidth)
				this.toggleChat()
			}
			this.timerInstance = setInterval(() => {
				++this.timeStartedCounter
				var hour = Math.floor(this.timeStartedCounter /3600);
				var minute = Math.floor((this.timeStartedCounter - hour*3600)/60);
				var seconds = this.timeStartedCounter - (hour*3600 + minute*60);
				if(hour < 10)
					hour = "0"+hour;
				if(minute < 10)
					minute = "0"+minute;
				if(seconds < 10)
					seconds = "0"+seconds;

				this.timeDuration = hour + ":" + minute + ":" + seconds
			}, 1000)
		}
    },
	computed: {
		started(){
			return this.$store.state.cxi.media_time_started
		},
		media_type() {
			return this.$store.state.cxi.audio ? 'audio' : 'video'
		}
	},
	watch: {
		started(val){
			if(val && this.timeStartedCounter == 0){
				this.startTimeDuration()
			}else{
				this.timeStartedCounter = 0
				this.timeDuration = '00:00:00'
			}
		},
		media_type(val) {
			if(val == 'audio'){
				this.video = false	
			}else{
				this.video = true	
			}
		}
	}

}
</script>
<style lang="scss">
.botter-cxi--active {
	.cxi_new_message{
		font-size: 12px;
		display: inline-block;
		margin: 0 2px;
	}
	.botter-modal-active ~ #botter-cxi-video-wrap {
		width: calc(100% - 400px);
	}

	#botter-cxi-video-wrap {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		position: fixed;
		height: 100vh;
		width: 100%;
		left: 0;

		#cxi-media-container {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;

			video {
				max-width: 100%;
				width: 100%;
				display: block;
				-o-object-fit: cover;
				object-fit: cover;
			}

			#videolocal {
				width: 100px;
				height: 100px;
				position: absolute;
				top: 24px;
				right: 24px;
				z-index: 10;
				-webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
				box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
				border-radius: 6px;
				overflow: hidden;
			}

			#videoremote0 {
				width: 100%;
				height: 100%;
				-webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5) inset;
				box-shadow: 0 0 6px rgba(0, 0, 0, 0.5) inset;
			}
			#audiobg{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				z-index: 1;
				background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #015490), color-stop(84%, #0276c3));
				background-image: -o-linear-gradient(left, #015490 0, #0276c3 84%);
				background-image: linear-gradient(90deg, #015490 0, #0276c3 84%);
				-webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5) inset;
				box-shadow: 0 0 6px rgba(0, 0, 0, 0.5) inset;
			}
		}
		.botter-cxi--actions-wrap{
			position: absolute;
			bottom: 100px;
			z-index: 1000;
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
				flex-direction: column;
		}
		.botter-cxi--agent-info{
			margin-bottom: 58px;
			text-align: center;
			color: white;
			text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
			.agent--name{
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 6px;
			}
			.conversation--time{
				font-size: 12px;
			}
		}
		.botter-cxi--local-actions {
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			height: 0;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;

			&:before {
				content: "";
				width: 100%;
				display: block;
				height: 300px;
				position: absolute;
				-webkit-box-shadow: 0 -260px 200px -200px #000000d9 inset;
				box-shadow: 0 -260px 200px -200px #000000d9 inset;
			}

			button {
				width: 48px;
				height: 48px;
				margin-left: 16px;
				margin-right: 16px;
				border-radius: 100%;
				background: rgba(255, 255, 255, 0.25);
				color: white;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				font-size: 24px;
				-webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
				box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
				-webkit-transform: scale(1) translateY(0);
				-ms-transform: scale(1) translateY(0);
				transform: scale(1) translateY(0);
				-webkit-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				transition: all 0.3s ease;
				-webkit-font-smoothing: subpixel-antialiased;
				-webkit-backface-visibility: hidden;
				        backface-visibility: hidden;
				&:hover {
					color: #000;
					background: rgba(255, 255, 255, 0.75);
					-webkit-transform: scale(1.1) translateY(-4px);
					-ms-transform: scale(1.1) translateY(-4px);
					transform: scale(1.1) translateY(-4px);
					-webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.32);
					box-shadow: 0 0 6px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.32);
				}

				&.botter-cxi--action-end {
					background: crimson;
					color: white;
				}
			}
		}
	}

	.botter-modal-toggle-btn{
		display: none;
	}
	.botter-modal {
		top: 0;
		right: 0;
		width: 400px;
		height: 100vh;
		margin: 0;
		

		&.botter-modal-active {
			max-height: 100%;
			border-radius: 0;

			.botter-modal-head {
				border-radius: 0;
			}
			
			~ .botter-modal-toggle-btn {
				display: none;
			}
		}
	}

}

@media (max-width: 776px) {
	.botter-cxi--active {
		.botter-modal-active ~ #botter-cxi-video-wrap {
			width: 100%;
		}
		#botter-cxi-video-wrap {
			#cxi-media-container {

				.botter-cxi--actions-wrap{
					bottom: 200px;
				}
				.botter-cxi--local-actions {
					&:before{
						height: 400px;
					}
				}
			}
		}
		.botter-modal {
			width: 100%;
		}
	}
}
</style>