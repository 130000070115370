export default class CobrowseConfig  {
	// Cobrowse (broker server) url
	// Eg. completeUrl = ‘wss://server.url.here/cobrowse-server’;
	completeUrl = process.env.VUE_APP_CXI_COBROWSE
	configuration = {
		fullyQualifiedURL: this.completeUrl,
		retryPolicy: {
			reconnectInterval: 2000,
			maxReconnectAttempts: 3
		}
	}
	// Retry policy configuration
	reconnectPolicy = { reconnectInterval: 3000, maxReconnectAttempts: 5}
	user =  JSON.parse(window.localStorage.getItem('botter.user') || '')
	// Visitor’s unique Id
	cbClientId = this.user.membership_id
	// Unique session Id which will
	// be passed in each server request.
	cbSessionId =  ''
	// Visitor’s details
	// Eg. this.clientDetails = new ClientDetails(011, “Delson Smith”);
	cbclientDetails = {id: this.user.membership_id, name: this.user.user_name}
	// Configuration map for masked fields.
	// Eg. const configMap = new Map();
	// 	   configMap.set('id', ['croot']);
	// 	   this.maskConfigMap = configMap;
	maskConfigMap = null

	set clientId(id) {
		this.cbClientId = id;
	}
	get clientId() {
		return this.cbClientId;
	}
	set sessionId(id) {
		this.cbSessionId = id;
	}
	get sessionId() {
		return this.cbSessionId;
	}
	set clientDetails(details) {
		this.cbclientDetails = details;
	}
	get clientDetails() {
		return this.cbclientDetails;
	}
	set maskAttributes(map) {
		this.maskConfigMap = map;
	}
	get maskAttributes() {
		return this.maskConfigMap;
	}
}