<template>
	<div ref = "modalContainer" class="botter-wdiget-body">
		<div :class="['botter-modal-chat', { 'botter-chat-body-shrinked': inputPulledUp }]">
			<div class="botter-modal-chat-container">
				<transition-group name="slide">
					<div 
						v-for="(con,index) in conversation" 
						:key="`${con.slug}${index}`">
						<component
						v-if = "inputPulledUp || !isInputPullingSlug(con.slug)"
						:is="getFileName(con.slug || con.botterPayload && con.botterPayload.slug)"
						:con="con"
						:index="index"
						@postBack="postBack"
						@postText="postText"
						@postFile="postFile"
						@update-input="handleUpdateInput" />
					</div>
				</transition-group>
        <emojiPicker :open="showEmoji" @add="addEmoji" @close="emojiClose" />
			</div>
		</div>
		<div :class="['botter-chat-action', { 'botter-chat-action-pulled-up': inputPulledUp }]" v-show="!inputDisabled">
			<snackbar v-if="!connected">
				{{ $t('You are disconnected we are trying to reconnect again') }}
			</snackbar>
			<form 
				class="botter-user-input d-flex align-center"
				@submit.prevent="sendMessage">
				<presist-menu style="height: 28px" @postBack="postBack"></presist-menu>
				<input type="file" class="botter-file-uploader" accept=".txt, .jpg, .jpeg, .png, .gif, .pdf, .csv, .doc, .docx, .ppt, .pptx, .xls, .xlsx" @change="uploadFile" ref="file" >
				<span @click="$refs.file.click()" class="botter-svg">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
						<defs>
							<linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%">
								<stop offset="0%" stop-color="#015998"/>
								<stop offset="100%" stop-color="#0276C3"/>
							</linearGradient>
						</defs>
						<g fill="none" fill-rule="evenodd">
							<circle cx="16" cy="16" r="16" fill="url(#prefix__a)"/>
							<path fill="#FFF" fill-rule="nonzero" d="M23.124 14.75H17.25V8.886c0-.482-.39-.886-.873-.886h-.752c-.483 0-.875.404-.875.886v5.864H8.875c-.482 0-.875.39-.875.873v.756c0 .482.393.871.875.871h5.875v5.885c0 .483.392.865.875.865h.752c.482 0 .873-.383.873-.865V17.25h5.874c.483 0 .876-.389.876-.87v-.757c0-.483-.393-.873-.876-.873z"/>
						</g>
					</svg>
				</span>
        <div class="botter-user-input-field-wrap">
          <input
            v-if ="!inputPulledUp"
            v-model="userInput"
            :disabled="!connected || inputDisabled"
            type="text"
            class="botter-user-input-field"
            id="botter-user-input-field"
            spellcheck="true"
            autocomplete="off"
            :placeholder="$store.state.core.widgetParameters.inputPlaceHolder || 'write a replay'"
          />
          <div v-else class="botter-user-input-label">
            <p>{{ inputLabel }}</p>
          </div>

          <button type="button" ref="emojiButton" @click="toggleEmoji" class="botter-user-button-emoji">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
          </button>
        </div>

				<send-button @click = "handleSendAction" />
				<record-button @click = "handleRecordAction" />
				<record-voice v-if="showRecorder" @record="handleRecordCompleteAction" @cancel="handleRecordCancelAction" />
				<div id="sound"></div>
			</form>
		</div>
	</div>
</template>

<script>
import common from '../utils/common'
import BotSocket from '../modules/botSocket'
import textData from './messagesType/textData'
import imageData from './messagesType/imageData'
import heroData from './messagesType/heroData'
import triviaQuestionData from './messagesType/triviaQuestionData'
import galleryData from './messagesType/galleryData'
import typingData from './messagesType/typingData'
import videoData from './messagesType/videoData'
import audioData from './messagesType/audioData'
import flightStatusData from './messagesType/flightStautsData'
import flightPassngersData from './messagesType/travelData'
import adaptiveCard from './messagesType/adaptiveCard'
import snackbar from './UIElements/snackbar'
import sendButton from './UIElements/sendButton'
import recordButton from './UIElements/recordButton'
import emojiPicker from './UIElements/emojiPicker'
import BotterLogo from './UIElements/botterLogo'
import gifData from './messagesType/gifData'
import cardDateTimeInputData from './messagesType/cardDateTimeInputData'
import weatherData from './messagesType/weatherData'
import receiptData from './messagesType/receiptData'
import mapData from "./messagesType/mapData";
import attachmentData from "./messagesType/attachment";
import userInputData from "./messagesType/userInputData";
import multiInputData from "./messagesType/multiInputData";
import escalateData from "./messagesType/escalateData";
import eventData from './messagesType/eventData'
import presistMenu from "./menu/presistMenu";
import RecordVoice from './UIElements/recordVoice.vue'


export default {
  name: 'WidgetBody',
  components: {
    BotterLogo,
    textData,
    imageData,
    heroData,
    triviaQuestionData,
    galleryData,
    typingData,
    videoData,
    audioData,
    adaptiveCard,
    snackbar,
    sendButton,
    recordButton,
    gifData,
    flightStatusData,
    flightPassngersData,
    cardDateTimeInputData,
    weatherData,
    receiptData,
    mapData,
    attachmentData,
    userInputData,
    multiInputData,
    presistMenu,
    escalateData,
    RecordVoice,
    eventData,
    emojiPicker
  },
  props: {
    sessionActive: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    modalActive: {
      type: Boolean,
      default: false
    },
    onFocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userInput: '',
      inputLabel: '',
      socket: {},
      slugs: [
        'text',
        'image',
        'attachment',
        'hero',
        'gallery',
        'typing',
        'video',
        'audio',
        'gif',
        'trivia-question',
        'flight-status',
        'flight-passngers',
        'card-date-time-input',
        'weather',
        'receipt',
        'map',
        'user-input',
        'multi-input',
        'escalate',
        'event'
      ],
      textSlugs: ['prompt-input','prompt-date','prompt-multi-input'],
      adaptiveCards: ['receipt', 'weather'],
      bottomInputSlugs: [''],
      showRecorder: false,
      showEmoji: false
    }
  },

  computed: {
    conversation() {
      return this.$store.state.messages.conversationList.map((v) => {
        if(v.created_at){
          v.date = v.created_at
        }
        return v
      })
    },
    connected() {
      return this.$store.state.messages.connected
    },
    inputDisabled() {
      return this.$store.state.messages.inputDisabled
    },
    inputPulledUp() {
      if (this.conversation && this.conversation.length)
        return this.isInputPullingSlug(
          this.conversation[this.conversation.length - 1].slug
        )
      return false
    }
  },
  watch: {
    'conversation.length': function() {
      if (common) common.scrollHeight()
    },
    sessionActive() {
      if (this.sessionActive) {
        this.initSession()
      }
    },
    userInput() {
      let arabic = /[\u0600-\u06FF]/
      let input = document.getElementById('botter-user-input-field')
      if (arabic.test(this.userInput)) {
        input.style.direction = 'rtl'
      } else  {
        input.style.direction = 'ltr'
      }
      if (this.userInput && (this.userInput.replace(/\s/g, '').length) ) {
        this.$eventBus.$emit('cxi.webrtc.sendSneakMessage', this.userInput)
      }
    },
    conversation() {}
  },
  mounted() {
    // listen to enter press
    document.addEventListener('keydown', e => {
      if (e.key === 'Enter')
        this.submitFakeInput()
    })
    this.initSession()
    this.sendCXIEvents()
    this.checkCXISession()
  },
  methods: {
    addEmoji(emoji){
      this.userInput += emoji
    },
    emojiClose(e){
      // console.log('close', e.target, this.$refs.emojiButton)
      if(this.showEmoji && e.target != this.$refs.emojiButton){
        this.showEmoji = false
      }
    },
    toggleEmoji(){
      this.showEmoji = !this.showEmoji
    },
    initSession() {
      this.socket = new BotSocket()
    },
    //=========[ CXI ]==================================
    checkCXISession(){
      let cxi_session = JSON.parse(window.sessionStorage.getItem('cxi.chat.session') || '{}') 
      if(cxi_session.sessionId && cxi_session.accepted){
        this.$store.dispatch('pushMessage', {
          botConfig: {
            driver: 'cxi-webrtc'
          },
          slug: 'escalate',
          payload: this.userInput,
          type: 'user',
          lastMessageGroup: true
        })
      }else{
        this.$store.dispatch('cxi_end_session')
      }
    },
    async sendCXIEvents() {
      this.$eventBus.$on('cxi.webrtc.events', (event) => {
        this.socket.sendMessage({
          event: event.type,
            type: 'report_event',
          })
      })

      this.$eventBus.$on('cxi.webrtc.event_message', (message) => {
        this.socket.sendMessage({
          text: message,
            type: 'cxi_webrtc_message',
            cxi_webrtc_session_id: this.$store.state.cxi.chat_session_info.sessionId,
            sender_type: 'system'
          })
      })

      this.$eventBus.$on('cxi.webrtc.agent_message', (message) => {
        this.socket.sendMessage({
          text: message,
          type: 'cxi_webrtc_message',
          cxi_webrtc_session_id: this.$store.state.cxi.chat_session_info.sessionId,
          sender_type: 'agent'
        })
      })

      this.$eventBus.$on('botter.blocks', (data) => {
          // let block_id_string = `block_id:{${data.block_id.join()}}`
          let block_id_string = `block_id:{${data.block_id}}`
          this.socket.sendMessage({
            text: block_id_string,
            type: 'message',
            postback: {
              type: 'postBack',
              title: data.title,
              value: block_id_string,
            }
          })
      })
    },
    //==================================================
    async sendMessage() {
      let connectedToCXI = this.$store.state.cxi.text

        // console.log('sendMessage')
      if (this.userInput && (this.userInput.replace(/\s/g, '').length) ) {
        try {
          if(connectedToCXI){
            // console.log('connectedToCXI', connectedToCXI)
            this.$eventBus.$emit('cxi.webrtc.message', this.userInput)
            
            this.socket.sendMessage({
              text: this.userInput,
              type: 'cxi_webrtc_message',
              cxi_webrtc_session_id: this.$store.state.cxi.chat_session_info.sessionId,
              sender_type: 'user'
            })
            // window.botter_CXI.sendMessage(this.userInput)
          }else{
            await this.socket.sendMessage({
              text: this.userInput,
              type: 'message',
              slug: 'text'
            })
          }
            await this.$store.dispatch('pushMessage', {
              slug: 'text',
              payload: this.userInput,
              type: 'user',
              lastMessageGroup: true
            })
          this.userInput = ''
        } catch (err) {
          // error to be handled
        }
      }
    },
    async uploadFile(event) {
      let connectedToCXI = this.$store.state.cxi.text
      let formObject = new FormData()
      const file =  event.target.files[0]

      const showLoading = () => {
        this.$store.dispatch('pushMessage', {
            slug: 'typing',
            type: 'bot',
            duration: 2900,
            lastMessageGroup:  true
        })
      }
      showLoading()
      let loading = setInterval(showLoading, 3000)

      if(connectedToCXI){
            formObject.append("userPhoto", file, file.name)
            this.$http_cxi_upload.post('/fileoperation/upload',formObject)
              .then(response=>{
                console.log('upload cxi', response)
                let fileName = response.data.fileName
                const apiCxiBaseUpload = document.getElementById('botter-widget').getAttribute('upload-api-cxi-url') || process.env.VUE_APP_CXI_BASE_UPLOAD;
                this.$store.dispatch('pushMessage', {
                  slug: file.type.match('image/*') ? 'image' : 'attachment',
                  mediaUrl: `${apiCxiBaseUpload}/fileoperation/download/${fileName}`,
                  type: 'user',
                  fileName: fileName,
                  lastMessageGroup: true
                })

                // window.botter_CXI.sendFile(fileName, file)
                this.$eventBus.$emit('cxi.webrtc.file', {
                  message: fileName,
                  file
                })
                clearInterval(loading)
              })
              .catch(error=>{
                console.error(error)
                clearInterval(loading)
              })

          }else{
            formObject.append("file", file)
            this.$http_upload.post('/file/upload',formObject)
              .then(response=>{
                let image = response.data.url
                 this.$store.dispatch('pushMessage', {
                  slug: response.data.type,
                  mediaUrl: image,
                  type: 'user',
                   fileName: response.data.name,
                  lastMessageGroup: true
                })
      
                let attachmentObject =  {
                  type: 'attachment',
                  url: image,
                  attachment_type: response.data.type
                }
      
                this.socket.sendMessage({
                  slug: response.data.type,
                  type: 'message',
                  file_name: response.data.name,
                  text: JSON.stringify(attachmentObject)
                })
                clearInterval(loading)
              })
              .catch(error=>{
                console.error(error)
                clearInterval(loading)
              })
          }
    },
    postFile(file) {
      let attachmentObject =  {
        type: 'attachment',
        url: file.url,
        attachment_type: file.type
      }

      this.socket.sendMessage({
        slug: file.type,
        type: 'message',
        file_name: file.name,
        text: JSON.stringify(attachmentObject)
      })
    },
    async postBack(postData) {
      await this.socket.sendMessage({
        text: postData.value || postData.payload,
        type: 'message',
        postback: postData
      })
    },
    postText (postData) {
      this.socket.sendMessage({
        text: postData,
        type: 'message',
        slug: 'text'
      })
    },
    getFileName(slug) {
      if (this.slugs.includes(slug)) {
        return slug + '-data'
      } else if (this.textSlugs.includes(slug)) {
        return 'text-data'
      }else if(slug == 'card-escalate'){
        return 'escalate-data'
      }
    },
    // decides whether the given slug will pull the actionInput up or not
    isInputPullingSlug(slug) {
      return this.bottomInputSlugs.includes(slug)
    },
    handleUpdateInput(e) {
      this.userInput = e
      if (e.label) this.inputLabel = e.label
    },
    handleSendAction() {
      this.sendMessage()
    },
    handleRecordAction() {
      this.showRecorder = true
    },
    // handleRecordCompleteAction(blob){
    //   this.showRecorder = false
    //   this.uploadFile({
    //     target: {
    //       files: [new File([blob], `record${Date.now()}.wav`, {type: blob.type} )]
    //     }
    //   })
    // },
    handleRecordCompleteAction(file){
      this.showRecorder = false
      this.uploadFile({
        target: {
          files: [file]
        }
      })
    },
    handleRecordCancelAction(){
      this.showRecorder = false
    },
    submitFakeInput() {
      if (this.onFocus && this.inputPulledUp)
        this.sendMessage()
    },
    
  }
}
</script>

<style  lang="scss" src="../style/components/widgetBody.scss">
</style>
