import BrokerInterceptorImplementation  from './SignalingBrokerInterceptor';
import { StanderdWSMessageBrokerClient } from 'cxi-message-broker-client/message-broker/client/websocket/StandardWSMessageBrokerClient';
import  WeRTCBrokerInterceptor  from './WeRTCBrokerInterceptor';
import { StandardWebRTCMessageBrokerClient } from 'cxi-message-broker-client/message-broker-webrtc/client/StandardWebRTCMessageBrokerClient';
import platform from 'platform'
import store from '../../store'

const TenantId = process.env.VUE_APP_CXI_TENANT_ID
const PageName = process.env.VUE_APP_CXI_PAGE_NAME
const AccountId = process.env.VUE_APP_CXI_ACCOUNT_ID

class SignallingClient {
    signallingConnectionEstablished = false
    signalingConn = ''
    webrtcBridge = ''
    sessionId = ''
    interactionId = ''
    $eventBus = {}

    setEventBus(eventBus) { 
        this.$eventBus = eventBus
    }

    connectSignalling(websocketServerURL, clientType, clientID, agentName) {
        const brokerConf = {
            fullyQualifiedURL: websocketServerURL,
            retryPolicy: {
                reconnectInterval: 2000,
                maxReconnectAttempts: 3
            }
        };
        console.log('connectSignalling')
        if (this.signalingConn && this.signalingConn.isConnected()) {
            console.warn('Client already connected to signaling');
            this.connectWebChat()
        } else {
            console.log('signallingConnectionEstablished', this.signallingConnectionEstablished)

            if (!this.signallingConnectionEstablished) {
                console.log('after signallingConnectionEstablished', brokerConf)
                this.signalingConn = new StanderdWSMessageBrokerClient(
                    clientID,
                    brokerConf,
                    new BrokerInterceptorImplementation(this, this.$eventBus)
                );
                
                this.webrtcBridge = new StandardWebRTCMessageBrokerClient(
                    this.signalingConn,
                    new WeRTCBrokerInterceptor(),
                    false
                );
                this.signallingConnectionEstablished = true;
            }else{
                this.connectWebChat()
            }
        }
    }

    updateClientId(clientId) {
        this.signalingConn.updateClientId(clientId);
    }

    toggleMute() {
        this.webrtcBridge.toggleMute();
    }

    toggleVideo() {
        this.webrtcBridge.toggleVideo();
    }

    endcall() {
        this.webrtcBridge.endCall(this.sessionId, this.interactionId);
        store.dispatch('cxi_end_call')
        console.log('End call')
        this.$eventBus.$emit('cxi.webrtc.end_call', 'Call ended')
    }

    endChat() {
        let session_data = store.state.cxi.chat_session_info
        if(session_data.payload && session_data.payload.blocksFinishing){
            session_data.payload.blocksFinishing.forEach(id => {
                this.$eventBus.$emit('botter.blocks', {
                    title: 'End chat with agent',
                    block_id: id
                })
            })
        }

        this.webrtcBridge.endCall(this.sessionId, this.interactionId);
        this.signalingConn.closeSession(this.sessionId, this.interactionId, true);
        this.disconnectFromServer(false)
        store.dispatch('cxi_end_chat')
        console.log('End Chat')
        this.$eventBus.$emit('cxi.webrtc.end_call', 'Chat ended')
        this.signallingConnectionEstablished = false
    }

    isMediaCallActive() {
        return this.webrtcBridge.isMediaCallActive();
    }

    disconnectFromServer(action) {
        this.signalingConn.disconnect(action);
        console.log('::disconnectFromServer', action)
    }

    getSignalingConnection() {
        return this.signalingConn;
    }

    createOffer(offerId) {
        console.log(offerId);
    }

    setSessionId(interactionId, sessionId) {
        this.sessionId = sessionId;
        this.interactionId = interactionId;
    }

    getSessionId() {
        return this.sessionId;
    }

    getSessionIdFromChatSession() {
        return store.state.cxi.chat_session_info.sessionId || this.sessionId;
    }

    getInteractionId() {
        return this.interactionId;
    }

    getInteractionIdFromChatSession() {
        return store.state.cxi.chat_session_info.interactionId || this.interactionId;
    }

    getIPAddress(){
        return store.state.cxi.ip_address
    }

    preConnect(){
        const user = JSON.parse(window.localStorage.getItem('botter.user')) || {}
        const dynamicFields = Object.entries(user).map(v => {
            let obj = {}
            obj[v[0]] = Array.isArray(v[1]) ? v[1].join(', ') : v[1]
            return obj
        })
        const firstName = user.user_name.split(' ')[0]

        const data =  {
            tenantId: TenantId,
            browser: `${ platform.name } v${ platform.version}`,
            operatingSystem: '' + platform.os,
            chatStartDateTime: new Date(),
            customerFirstName: firstName,
            customerEmail: user.email,
            dynamicFields: dynamicFields,
            chatTranscript: undefined,
            feedBack: [],
            chatTags: [],
            chatNotes: [],
            chatSharedFiles: [],
            pageName: PageName,
            // pageName: 'DefaultPage',
            status: 'OPEN',
            unconfirmed: false
        };
        
        dynamicFields.push({'First Name': firstName})
        dynamicFields.push({'Page Name': PageName})
        dynamicFields.push({accountId: AccountId})
        dynamicFields.push({'IP Address': this.getIPAddress()})
        dynamicFields.push({'Browser': data.browser})
        dynamicFields.push({'Platform': data.operatingSystem})
        dynamicFields.push({'Screen Resolution': `${window.screen.width}*${window.screen.height}`})
        dynamicFields.push({'User Agent': platform.ua})
        dynamicFields.push({'Browser Language': window.navigator.language})
        dynamicFields.push({'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone})
    
        return data;
    }

    connectVideo() {
        let body = this.preConnect()
        body.interactionType = 'MEDIA'
        body.interactionSubType = 'VIDEO'
        body.channel = 'VIDEO'
        const media = {
            mediaConstraints: {
                audio: true,
                video: true
            }
        }
        // this.webrtcBridge.registerCall(body, media);
        if(this.isMediaCallActive()){
            this.webrtcBridge.initiateCall(this.getSessionIdFromChatSession(), this.getInteractionIdFromChatSession(), this.getInteractionIdFromChatSession(), media)
        }else{
            this.webrtcBridge.initiateCall(this.getSessionIdFromChatSession(), this.getInteractionIdFromChatSession(), this.getInteractionIdFromChatSession(), media)
        }
        store.dispatch('cxi_loading', true)
        console.warn('video calling,', body);
    }

    connectAudio() {
        let body = this.preConnect()
        body.interactionType = 'MEDIA'
        body.interactionSubType = 'VIDEO'
        body.channel = 'VIDEO'
        const media = {
            mediaConstraints: {
                audio: true,
                video: false
            }
        }
        // this.webrtcBridge.registerCall(body, media);
        this.webrtcBridge.initiateCall(this.getSessionIdFromChatSession(), this.getInteractionIdFromChatSession(), this.getInteractionIdFromChatSession(), media)
        store.dispatch('cxi_loading', true)
        console.warn('Audio calling,', body);
    }

    connectWebChat(){
        let body = this.preConnect()
        body.interactionType = 'CHAT'
        body.interactionSubType = 'WEBCHAT'
        body.channel = 'webchat'
        // console.log(body)

        const cxi_session = JSON.parse(window.sessionStorage.getItem('cxi.chat.session')) || {}

        if(cxi_session.interactionId){
            // this.setSessionId(cxi_session.interactionId, cxi_session.sessionId)
            this.signalingConn.registerExistingReqToBroker(cxi_session.interactionId)
        }else{
            this.signalingConn.registerNewReqToBroker(body)
        }
        store.dispatch('cxi_loading', true)
    }

    sendMessage(message){
        // console.log(message)
        const user = JSON.parse(window.localStorage.getItem('botter.user')) || {}
        const ChatBody = {
            senderId: this.getInteractionIdFromChatSession(),
            senderName: user.user_name,
            chatMessage: message,
            sentDateTime: new Date,
            isSenderAgent: false,
            type: 'text',
            fileName: '',
            size: 0,
            senderType: "Customer",
        }
        this.signalingConn.sendMessage(this.getSessionIdFromChatSession(), ChatBody)
        
    }

    sendSneakMessage(message){
        this.signalingConn.sendSneakMessage(this.getSessionIdFromChatSession(), message)
    }

    sendFile(message, file){
        const user = JSON.parse(window.localStorage.getItem('botter.user')) || {}
        const ChatBody = {
            senderId: this.getInteractionIdFromChatSession(),
            senderName: user.user_name,
            chatMessage: message,
            sentDateTime: new Date,
            isSenderAgent: false,
            type: file.type.match('audio.*') ? 'audio' :'file',
            fileName: message,
            size: file.size,
            senderType: "Customer",
        }
        this.signalingConn.sendMessage(this.getSessionIdFromChatSession(), ChatBody)
        
    }

    isConnected(){
        return this.signalingConn.isConnected()
    }
}

export const signallingClient = new SignallingClient()