import store from '../store'
import messages from "../utils/messages";

export default class BotSocket {

  config= {
    ws_url: '',
    reconnect_timeout: 3000,
    max_reconnect: 30,
    enable_history: false
  }

  options= {
    use_sockets: true,
    enable_history: true
  }

  socket = null
  reconnect_count= 0
  guid= null
  current_user= null
  bot_id = null
  newMessage = {}

  constructor() {
    let ws_url_param = document.getElementById('botter-widget').getAttribute('gateway-socket-url')
    if (store.state.core.widgetParameters.externalSocketBase) {
      this.config.ws_url = store.state.core.widgetParameters.externalSocketBase
    } else if (ws_url_param) {
      this.config.ws_url = ws_url_param
    } else {
      this.config.ws_url = process.env.VUE_APP_BASE_URL
    }

    if (!this.config.ws_url.includes('wss://') && !this.config.ws_url.includes('ws://') ) {
      this.config.ws_url = 'wss://' + this.config.ws_url
    }
    this.bot_id = store.state.core.botId
    this.connectWebsocket()
  }


  connectWebsocket () {

    // Create WebSocket connection.
    this.socket = new WebSocket(this.config.ws_url);
    let connectEvent = 'hello';
    if (this.getCookie('botkit_guid') && !store.state.messages.startNew) {
      this.guid = this.getCookie('botkit_guid');
      connectEvent = 'welcome_back';
    } else {
      if (this.getCookie('botkit_guid')) {
        this.guid = this.getCookie('botkit_guid');
      } else {
        this.guid = this.generate_guid();
        this.setCookie('botkit_guid', this.guid, 30);
      }

    }

    if (this.options.enable_history) {
      this.getHistory();
    }

    // Connection opened
    this.socket.addEventListener('open', (event) => {
      store.dispatch('setConnected',true)
        this.reconnect_count = 0;

      let attributes = []
      let customMessage = ''
      console.log('attributes', store.state.core.attributes)
      attributes = store.state.core.attributes.concat(store.state.core.externalAttributes)

      if (store.state.core.widgetParameters.allowParametersFromURL) {
        let URLAttributes = []
        URLAttributes = this.getAttributesFromURL()
        attributes = attributes.concat(URLAttributes)

        let customMessageObject = URLAttributes.find((param)=>{
          return param.attribute === 'type'
        })

        if (customMessageObject) {
          customMessage = customMessageObject.value
        }

      }

        this.deliverMessage({
          type: connectEvent,
          user: this.guid,
          channel: 'web',
          user_profile: this.current_user ? this.current_user : null,
          bot_id: this.bot_id,
          attributes: attributes
        });

      if (customMessage) {
        setTimeout(()=>{
          this.deliverMessage({
            slug: "text",
            text: customMessage,
            type: "message",
            user: this.guid,
            channel: 'web',
            user_profile: this.current_user ? this.current_user : null,
            bot_id: this.bot_id,
          });
        },2000)
      }

    });

    this.socket.addEventListener('error',  (event)=> {
      console.error('ERROR', event);
      store.dispatch('setConnected',false)
    });

    this.socket.addEventListener('close', (event)=> {
      store.dispatch('setConnected',false)
      if (this.reconnect_count < this.config.max_reconnect) {
        setTimeout( ()=> {
          console.log('RECONNECTING ATTEMPT ', ++this.reconnect_count);
          this.connectWebsocket();
        }, this.config.reconnect_timeout);
      } else {

      }
    });

    // Listen for messages

    this.socket.addEventListener('message', (event)=> {
      // this.newMessage = {};
      let message = JSON.parse(event.data) || {};
      this.newMessage = message
      // try {
      //   this.newMessage = message
      // } catch (err) {
      //   this.newMessage = {
      //     slug: 'text',
      //     text: message.text
      //   }
      // }
      if (this.newMessage.history) {
        // console.log('history', this.newMessage.history)
        let historyMessage = {}
        for (let i=0; i<this.newMessage.history.length;i++) {
          historyMessage = {}
          if (this.newMessage.history[i].sender.sender_type === 'BOT' || this.newMessage.history[i].sender.sender_type === 'AGENT' ) {
            historyMessage = {...this.newMessage.history[i]}
            historyMessage.type = 'bot'
          } else {
            historyMessage = {...this.newMessage.history[i]}
            historyMessage.payload = historyMessage.text
            if (!this.newMessage.history[i].slug) {
              historyMessage.slug = 'text'
            }
            historyMessage.type = 'user'
          }

          historyMessage.lastMessageGroup = true
          if (!['hello','welcome_back', 'set_attributes'].includes(this.newMessage.history[i].type)) {
            store.dispatch('pushMessage',historyMessage)
            // console.log(historyMessage)
          }

        }
        setTimeout(()=> {
          store.dispatch('setInputDisabled', false)
        },1000)

      } else {
        this.newMessage.type = 'bot'
        this.newMessage.lastMessageGroup = true
        store.dispatch('pushMessage',this.newMessage)
        if (message.slug === 'typing') {
          messages.playSound(store.state.core.widgetParameters.typingSound)
        } else {
          messages.playSound(store.state.core.widgetParameters.messageSound)
        }
      }


      messages.browserNotification()
    });
  }

  sendMessage(message) {
    this.deliverMessage({
      ...message,
      user: this.guid,
      channel: 'web',
      bot_id: this.bot_id
    })
  }

  // sendAttributes() {
  //   let attributes =store.state.core.attributes.concat(store.state.core.externalAttributes)
  //
  //   if (store.state.core.widgetParameters.allowParametersFromURL) {
  //     let URLAttributes = this.getAttributesFromURL()
  //     attributes = attributes.concat(URLAttributes)
  //   }
  //
  //   this.deliverMessage({
  //     type: 'set_attributes',
  //     attributes: attributes,
  //     user: this.guid,
  //     channel: 'web',
  //     bot_id: this.bot_id
  //   })
  // }

  getAttributesFromURL () {
    let query = this.getQueryParams()
    let parameters = []
    for (let key in query) {
      if (query.hasOwnProperty(key)) {
        parameters.push({
          attribute: key,
          value: query[key]
        })
      }
    }
    return parameters
  }

  getQueryParams () {
    let search = location.search.substring(1);
    if (search) {
      return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
    } else {
      return  {}
    }
  }

  deliverMessage(message) {
    this.socket.send(JSON.stringify(message));
  }

  getHistory() {

  }

  s4 () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  generate_guid () {
    let userId = document.getElementById('botter-widget').getAttribute('user_id')
    if (userId) {
      return userId
    } else {
      return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
        this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }
  }

  setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }



}
