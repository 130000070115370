import { render, staticRenderFns } from "./faqs.vue?vue&type=template&id=88cf76de&"
import script from "./faqs.vue?vue&type=script&lang=js&"
export * from "./faqs.vue?vue&type=script&lang=js&"
import style0 from "./faqs.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VForm,VIcon,VTextField})
