<template>
  <div class="text botter-conversation" v-if="cxi_loading">
	<div class="d-flex align-end justify-start">
      <user-chat-image :sender="{sender_type: 'AGENT'}" />
      <div class="botter-text botter-bot-text">
        <p><i class="mdi mdi-24px mdi-spin mdi-loading me-2"></i> <span>Connecting to agent ... {{ connectionTimeout }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>

import userChatImage from "./common/userChatImage";
export default {
	name: "escalateData",
	props: {
		con: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	components: {
      userChatImage
    },
	data(){
		return {
			timeout: 60, //60 sec
			connectionTimeout: 1
		}
	},
	mounted(){
		console.log(this.con)
		if(this.con.botConfig && this.con.botConfig.driver == 'cxi-webrtc'){
			this.cxi_driver()
		}
		if(this.con.botterPayload && this.con.botterPayload.payload && this.con.botterPayload.payload.blocksFinishing){
			let session_data = this.$store.state.cxi.chat_session_info
			session_data.payload = this.con.botterPayload.payload
			this.$store.dispatch('cxi_session_info', session_data)
		}
	},
	methods:{
		cxi_driver(){
			let self = this
			// const cxi_socket = process.env.VUE_APP_CXI_SOCKET
			this.$store.dispatch('cxi_start')

			self.$eventBus.$on('cxi.webrtc.register', (obj) => {
				//define timeout and try to connect with agent or close connection after timeout
				let tryToConnect = setInterval(()=> {
					if(!self.cxi_loading && self.cxi_chat_connected) {
						clearInterval(tryToConnect)
						return
					}

					if(++self.connectionTimeout >= self.timeout){
						clearInterval(tryToConnect)
						self.connectionTimeout = 1
						console.error('Timeout: No agent available')
					}
				}, 1000)
			})
		}
	},
	computed: {
		cxi_loading() {
			return this.$store.state.cxi.loading
		},
		cxi_chat_connected() {
			return this.$store.state.cxi.text
		},
	}
}
</script>