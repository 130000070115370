<template>
<span
  class="botter-svg botter-chart-send-button botter-chat-button"
  @click = "$emit('click', $event)">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <defs>
            <linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0%" stop-color="#015998"/>
                <stop offset="100%" stop-color="#0276C3"/>
            </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <circle cx="16" cy="16" r="16" fill="url(#prefix__a)"/>
            <path fill="#FFF" fill-rule="nonzero" d="M24.97 15.615L9.03 8.085l3.544 7.53H24.97zm0 .94H12.575l-3.545 7.53 15.942-7.53z"/>
        </g>
    </svg>
</span>
</template>

<script>
export default {
  name: 'sendButton'
}
</script>

<style lang="css" scoped>
  svg {
    fill: var(--primaryColor) !important;
  }
</style>
