<template>
  <div class="botter-wdiget-social">
    <a :href="$store.state.core.widgetParameters.facebookLink" v-if="$store.state.core.widgetParameters.facebookLink"><span class="mdi mdi-facebook-messenger"></span></a>
    <a :href="$store.state.core.widgetParameters.twitterLink" v-if="$store.state.core.widgetParameters.twitterLink"><span class="mdi mdi-twitter"></span></a>
    <a :href="$store.state.core.widgetParameters.whatsappLink" v-if="$store.state.core.widgetParameters.whatsappLink"><span class="mdi mdi-whatsapp"></span></a>
  </div>
</template>

<script>
  export default {
    name: "socialLinks"
  }
</script>

<style scoped lang="scss">
.botter-wdiget-social {
  width: 87%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 30px;
  text-align: center;
  a {
    margin: 0 8px;
    span {
      font-size: 23px;
      color: #aaa;
    }
    /*.mdi-facebook-messenger {*/
    /*  color: #4267B2;*/
    /*}*/
    /*.mdi-whatsapp {*/
    /*  color:#06D253 ;*/
    /*}*/
    /*.mdi-twitter {*/
    /*  color: #2AA9E0;*/
    /*}*/
  }
}
</style>
