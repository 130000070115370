import { BrowseWatchComponent, DataCommunicationService, WatchdogService , CobrowseEventHandler} from 'cxi-cobrowse';
// import {BrowseWatchComponent, DataCommunicationService} from 'cxi-cobrowse/esm2015/cxi-cobrowse'
// import {WatchdogService} from 'cxi-cobrowse/esm2015/lib/services/watchdog/watchdog.service'
import CobrowseConfig from './CobrowseConfig';
// import CobrowseBrokerInterceptor from './CobrowseBrokerInterceptor';
// import { MatDialog } from '@angular/material';

class CobrowseClient  {
    client = null
    $eventBus = {}

    setEventBus(eventBus) { 
        this.$eventBus = eventBus
    }

    connect(sessionId){
        if(!this.client){
            const config = new CobrowseConfig()
            config.sessionId = sessionId
            console.log('config', config, sessionId)
            // const events = new CobrowseBrokerInterceptor(this.$eventBus)
            const DataCommunication = new DataCommunicationService()
            const events = new CobrowseEventHandler(DataCommunication)
            const Watchdog = new WatchdogService(config, events, DataCommunication)
            this.client =  new BrowseWatchComponent(
                Watchdog,
                DataCommunication,
                false,
                config
            )
            
            this.client.enablePopUp = false
    
            DataCommunication.cbEvent.subscribe(eventDetail => {
                if (eventDetail) {
                    eventDetail = JSON.parse(JSON.stringify(eventDetail))
                    // console.log('DataCommunication', eventDetail)
    
                    this.$eventBus.$emit('cxi.cobrowse.events', eventDetail)
    
                    // switch (eventDetail.event) {
                    //     case 'OFFER_RECEIVED':
                    //         break;
                    //     case 'ANSWER_RECEIVED':
                    //         break;
                    //     case 'OFFER_RECEIVED':
                    //         break;
                    // }
                }
            });      
            this.client.ngOnInit()
            console.log('BrowseWatchComponent', this.client)
            
            this.$eventBus.$on('cxi.cobrowse.accept', (obj) => {
                if(obj.accepted){
                    // console.log('clicked yes accepted', obj)
                    Watchdog.acceptSession(obj.data.message.data.clientDetails.clientId)  
                }else{
                    Watchdog.declineSession(obj.data.message.data.clientDetails.clientId) 
                }
            })
        }
    }

    registerToServer() {
        console.log('registerToServer')
        this.client.registerToServer()
    }
}

export const cobrowseClient = new CobrowseClient()