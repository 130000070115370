import Vue from 'vue'
import './utils/cssInjection'
import wrap from '@vue/web-component-wrapper';
import App from './App.vue'
import store from './store'
import './plugins/axios'

// import "../node_modules/cxi-cobrowse/ext/js/mutation-summary.js"
// import "../node_modules/cxi-cobrowse/ext/js/tree-mirror.js"

// global components
import bBtn from './components/UIElements/b-btn'

import i18n from './i18n'
import vuetify from './plugins/vuetify';

const CustomElement = wrap(Vue, App);

window.customElements.define('botter-widget', CustomElement);


Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
});


Vue.component('b-btn', bBtn);

Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue()

setTimeout(()=> {
  window.__botter_chat__ = new Vue({
    el: "#botter-widget-app",
    render: h => h(App),
    i18n,
    vuetify,
    store
  }).$mount('#app')

},1000)
