<template>
    <div></div>
</template>

<script>
import {signallingClient} from '../../modules/CXI_WebRTC/SignallingClient'
import {cobrowseClient} from '../../modules/CXI_Cobrowse/CobrowseClient'
export default {
	name: "escalation",
	data(){
		return {
			cxi_webrtc_client: '',
			cxi_cobrowse_client: '',
			timeout: 60, //60 sec
			connectionTimeout: 1,
			registerCounter: 0
		}
	},
	methods:{
		cxi_driver(){
			let self = this
			const cxi_socket = process.env.VUE_APP_CXI_SOCKET
			// console.log(self.cxi_webrtc_client)
			if(self.cxi_webrtc_client && self.cxi_webrtc_client.isConnected()){
				if(self.registerCounter == 0){
					self.cxi_webrtc_client.connectWebChat()
					self.registerCounter++
				}
			}else{
				self.cxi_webrtc_client = signallingClient
				self.cxi_webrtc_client.setEventBus(self.$eventBus)
				self.cxi_webrtc_client.connectSignalling(cxi_socket)

				self.cxi_cobrowse_client = cobrowseClient
				self.cxi_cobrowse_client.setEventBus(self.$eventBus)


				self.$eventBus.$emit('cxi.webrtc.instance', self.cxi_webrtc_client)
				console.log('Connecting to CXI')

				// When websocket is ready to start connection to agent
				self.$eventBus.$on('cxi.webrtc.ready', () => {
					// self.$store.dispatch('cxi_channel', 'chat')
					if(self.registerCounter == 0){
						self.cxi_webrtc_client.connectWebChat()
						self.registerCounter++
					}
				})
				// When registerd and send request to agent
				self.$eventBus.$on('cxi.webrtc.register', (obj) => {
					// console.warn(obj)
					self.cxi_cobrowse_client.connect(obj.interactionId)
					setTimeout(() => {
						self.cxi_cobrowse_client.registerToServer()
					}, 1000)
					//define timeout and try to connect with agent or close connection after timeout
					let tryToConnect = setInterval(()=> {
						if(!self.cxi_loading && self.cxi_chat_connected) {
							clearInterval(tryToConnect)
							return
						}

						if(++self.connectionTimeout >= self.timeout){
							clearInterval(tryToConnect)
							self.connectionTimeout = 1
							console.error('Timeout: No agent available')
							//send blocks to botter
							if(self.cxi_session.payload && self.cxi_session.payload.blocksNotAvailable){
								self.cxi_session.payload.blocksNotAvailable.forEach(id => {
									self.$eventBus.$emit('botter.blocks', {
										title: 'Not Available',
										block_id: id
									})
								});
							}
							//remove socket connection & clean session
							setTimeout(() => {
								self.cxi_webrtc_client.endChat()
							}, 100)
						}
					}, 1000)
				})
				// When client is connected to agent
				self.$eventBus.$on('cxi.webrtc.connected', (status) => {
					if(status){
						self.$store.dispatch('cxi_channel', 'chat')
					}
				})
				// client send a message to agent
				self.$eventBus.$on('cxi.webrtc.message', (message) => {
					self.cxi_webrtc_client.sendMessage(message)
					self.$store.commit('cxi_reset_unread_messages')
				})

				self.$eventBus.$on('cxi.webrtc.sendSneakMessage', (message) => {
					if(self.cxi_webrtc_client && self.cxi_webrtc_client.isConnected()){
						self.cxi_webrtc_client.sendSneakMessage(message)
					}
				})
				// client send a file to agent
				self.$eventBus.$on('cxi.webrtc.file', (obj) => {
					self.cxi_webrtc_client.sendFile(obj.message, obj.file)
				})

				self.$eventBus.$on('cxi.webrtc.end', (closed) => {
					if(!closed){
						self.cxi_webrtc_client.endChat()
					}
					self.registerCounter--
				})
			}

		}
	},
	computed: {
		cxi_start(){
			return this.$store.state.cxi.start
		},
		cxi_connected() {
			return {
				video: this.$store.state.cxi.video,
				audio: this.$store.state.cxi.audio,
				text: this.$store.state.cxi.text,
				action_token: this.$store.state.cxi.action_token
			}
		},
		cxi_loading() {
			return this.$store.state.cxi.loading
		},
		cxi_chat_connected() {
			return this.$store.state.cxi.text
		},
		cxi_session(){
			return this.$store.state.cxi.chat_session_info
		}
	},
	watch: {
		cxi_start(val, oldVal){
			if(val && !oldVal && this.registerCounter === 0){
				this.cxi_driver()
			}
		},
		cxi_connected(val) {
			if(val){
				// console.log('connectSignalling', this.$store.state.cxi.currentConnect)
				const currentConnect = this.$store.state.cxi.currentConnect

				if(currentConnect == 'media' && val.video && !val.audio) {
					console.log('connectVideo')
					this.cxi_webrtc_client.connectVideo()
				} else if(currentConnect == 'media' && val.audio && !val.video) {
					console.log('connectAudio')
					this.cxi_webrtc_client.connectAudio()
				}
			}
		},
		registerCounter(val){
			console.log('registerCounter', val)
		}
	}
}
</script>

<style scoped>

</style>
