<template>
<span
  class="botter-svg botter-chart-send-button botter-chat-button ms-2"
  @click = "$emit('click', $event)">
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <defs>
          <linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stop-color="#015998"/>
              <stop offset="100%" stop-color="#0276C3"/>
          </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
          <circle cx="16" cy="16" r="16" fill="url(#prefix__a)"/>
          <path transform="translate(6, 6)" fill="#FFF" fill-rule="nonzero" d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z" />
      </g>
  </svg>

</span>
</template>

<script>
export default {
  name: 'recordButton'
}
</script>

<style lang="css" scoped>
  svg {
    fill: var(--primaryColor) !important;
    text-align: center;
  }
</style>
