<template>

    <div v-click-outside="close" class="botter-user-input-emoji">
        <VEmojiPicker v-if="open" @select="addEmoji" />
    </div>
   
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { VEmojiPicker } from 'v-emoji-picker';
  export default {
    name: "emoji-picker",
    components: {
        VEmojiPicker,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
            require: true
        },
    },
    // data(){
    //     return {
    //         open: this.show
    //     }
    // },
    methods: {
        addEmoji(emoji){
            // this.open = false
            console.log(emoji)
            this.$emit('add', emoji.data)
        },
        // toggle(){
        //     this.open = !this.open
        // },
        close(e){
            setTimeout(() => {
                this.$emit('close', e)
            }, 100)
        }
    },
    mounted () {
        this.popupItem = this.$el
    },
    directives: {
        ClickOutside
    },
    // watch: {
    //     show(val){
    //         this.open = val
    //         console.log(val, this.open)
    //     }
    // }
  }
</script>

<style  lang="scss">
.emoji-picker {
    --ep-color-bg: #eaf0f7;
    --ep-color-sbg: #f6f6f6;
    --ep-color-border: #dadfe5;
    --ep-color-text: #444;
    --ep-color-active: #0079c8;
}

.emoji{
    padding: 3px !important;
    box-sizing: content-box;
}
.container-emoji{
    height: 421px;
}
</style>
