//TODO
/**
 * check chat connected
 * check video or audio connected
 * open chat coonection alone
 * open video/audio chat alone
 */
export const cxi = {
    state: {
        start: false,
        ready: false,
        video: false,
        audio: false,
        text: false,
        action_token: 0,
        chat_session_info: {},
        media_session_info: {},
        media_time_started: false,
        agent_info: {
            name: ''
        },
        agent_online: false,
        ip_address: '',
        currentConnect: '',
        loading: false,
        unread_messages: 0,

    },
    mutations: {
        'SET_CXI_READY' (state, status) {
            state.ready = status
        },
        'SET_CXI_VIDEO' (state, status) {
            state.video = status
            state.currentConnect = status ? 'media' : 'chat'
        },
        'SET_CXI_AUDIO' (state, status) {
            state.audio = status
            state.currentConnect = status ? 'media' : 'chat'
        },
        'SET_CXI_TEXT' (state, status) {
            state.text = status
            state.currentConnect = 'chat'
        },
        'SET_CXI_AGENT_INFO' (state, agent_info) {
            state.agent_info = agent_info
        },
        'SET_CXI_CHAT_SESSION_INFO' (state, session_info) {
            state.chat_session_info = session_info
            window.sessionStorage.setItem('cxi.chat.session', JSON.stringify(session_info))
        },
        'SET_CXI_MEDIA_SESSION_INFO' (state, session_info) {
            state.media_session_info = session_info
            window.sessionStorage.setItem('cxi.media.session', JSON.stringify(session_info))
        },
        'SET_CXI_MEDIA_TIME_STARTED' (state, status) {
            state.media_time_started = status
        },
        'SET_CXI_LOADING' (state, status) {
            state.loading = status
        },
        cxi_reset_unread_messages(state) {
            state.unread_messages = 0
        },
        cxi_add_unread_messages(state) {
            state.unread_messages++
        },
        'SET_AGENT_ONLINE' (state, status) {
            state.agent_online = status
        },
    },
    actions: {
        cxi_start({state}) {
            state.start = true
        },
        cxi_ready ({commit, state}, ip_address) {
            commit('SET_CXI_READY', true)
            state.ip_address = ip_address
            
            // if(!status){
            //     commit('SET_CXI_STARTED', false)
            //     commit('SET_CXI_VIDEO', false)
            //     commit('SET_CXI_AUDIO', false)
            //     commit('SET_CXI_TEXT', false)
            // }
        },
        cxi_channel({commit, state}, channel) {
            state.action_token++
            switch(channel) {
                case 'video':
                    return commit('SET_CXI_VIDEO', true);
                case 'audio':
                    return commit('SET_CXI_AUDIO', true);
                default:
                    return commit('SET_CXI_TEXT', true)
            }
        },
        cxi_agent_info({commit}, info) {
            commit('SET_CXI_AGENT_INFO', info)
        },
        cxi_session_info({commit, state}, info) {
            if(state.currentConnect == 'media'){
                commit('SET_CXI_MEDIA_SESSION_INFO', info)
            }else{
                commit('SET_CXI_CHAT_SESSION_INFO', info)
            }
        },
        cxi_media_time_started({commit}, status) {
            commit('SET_CXI_MEDIA_TIME_STARTED', status)
        },
        cxi_end_call({commit, dispatch}) {
            dispatch('cxi_ready', false)
            dispatch('cxi_loading', false)
            commit('SET_CXI_VIDEO', false)
            commit('SET_CXI_AUDIO', false)
            commit('SET_CXI_MEDIA_TIME_STARTED', false)
            commit('SET_CXI_MEDIA_SESSION_INFO', {})
            window.sessionStorage.removeItem('cxi.media.session')
        },
        cxi_end_chat({dispatch, state}) {
            dispatch('cxi_end_session')
            dispatch('cxi_end_call')
            state.start = false
        },
        cxi_end_session({commit}) {
            commit('SET_CXI_TEXT', false)
            commit('SET_CXI_CHAT_SESSION_INFO', {})
            window.sessionStorage.removeItem('cxi.chat.session')
        },
        cxi_loading({commit}, status) {
            commit('SET_CXI_LOADING', status)
        },
        cxi_message({commit}, obj) {
            commit('SET_CXI_MESSAGE', obj)
        },
        cxi_agent_online({commit}, status){
            commit('SET_AGENT_ONLINE', status)
        }
    }
}