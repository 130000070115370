import Vue from "vue";
import i18n from 'i18n'

export default class common {
  static formatDate (date) {
    date = new Date(date)
    const today = new Date()
    const diffTime = Math.abs(today - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let time;
    if(diffDays >= 1){
      time = this.timeAgo(date)
    }else{
      time = this.formatAMPM(date)
    }
    return time
  }

  static  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  static scrollHeight(){

    setTimeout(()=>{
      let scrollElement = document.querySelector('.botter-modal-chat')
      if (scrollElement)
        scrollElement.scrollTop = scrollElement.scrollHeight + 50
    },50)

    setTimeout(()=>{
      let scrollElement = document.querySelector('.botter-modal-chat')
      if (scrollElement)
        scrollElement.scrollTop = scrollElement.scrollHeight + 50
    },2000)
  }

  static get_yt_iframe_from_url(url) {

      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
        ? match[2]
        : null;

  }

  static formatText (text) {
    let newText = this.replaceSpecialChars(text,'*', '<b>','</b>')
     newText = this.replaceSpecialChars(newText,'_', '<i>','</i>')
     newText = this.replaceSpecialChars(newText,'~', '<del>','</del>')
    return newText
  }

  static replaceSpecialChars(text,char,replaceStart, replaceEnd) {
    let count = 0

    for (let i=0; i<text.length; i++) {
      if (text.charAt(i) === char) {
          count++
          if (count % 2 === 0) {
            text = this.replaceCharIndex(text,i,replaceEnd)
          } else {
            text = this.replaceCharIndex(text,i,replaceStart)
          }
      }
    }

    if (count % 2 !== 0 && count > 0)  {

      let lastIndex = text.lastIndexOf(replaceStart)
      text = text.slice(0, lastIndex) + char + text.slice(lastIndex + replaceStart.length,text.length)
    }

    return text
  }

  static replaceCharIndex(string,index, replacement) {
    return (string.substr(0, index)) + replacement + (string.substr(index + 1));
  }

  static updateQueryStringParam(key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    var kvp = document.location.search.substr(1).split('&');
    let i=0;

    for(; i<kvp.length; i++){
      if (kvp[i].startsWith(key + '=')) {
        let pair = kvp[i].split('=');
        pair[1] = value;
        kvp[i] = pair.join('=');
        break;
      }
    }

    if(i >= kvp.length){
      kvp[kvp.length] = [key,value].join('=');
    }

    // can return this or...
    let params = kvp.join('&');

    // reload page with new params
    document.location.search = params;
  }

  static setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  static getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  static getTextAlignment (text) {
    let arabic = /[\u0600-\u06FF]/
    let english = /[a-zA-Z]/


    for (let i = 0; i < text.length; i++) {
      if (arabic.test(text.charAt(i))) {
        return 'botter-rtl'
      } else if (english.test(text.charAt(i))) {
        return  'botter-ltr'
      }

    }

    return  'botter-ltr'

  }

  static isEmptyObj(obj) {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
        return false;
    }
    return true;
  }

  static convertObjToArr (Obj) {
    let arr= []
    for (let key in Obj) {
      if (Obj.hasOwnProperty(key)) {
        arr.push({
          attribute: key,
          value: Obj[key]
        })
      }
    }
    return arr
  }

  static delete_cookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  static deserializeQueryUrl() {
    let search = location.search.substring(1);
    if (search) {
      try {
        return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      }
      catch (e) {
        return {}
      }

    }
    return {}
  }

  static  timeAgo(dateParam) {
    if (!dateParam) {
      return null;
    }

    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();


    if (seconds < 5) {
      return 'about a minute ago';
    } else if (seconds < 60) {
      return `about a minute ago`;
    } else if (seconds < 90) {
      return 'about a minute ago';
    } else if (minutes < 60) {
      return `${ minutes } minutes ago`;
    } else if (isToday) {
      return this.getFormattedDate(date, 'Today'); // Today at 10:20
    } else if (isYesterday) {
      return this.getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
    } else if (isThisYear) {
      return this.getFormattedDate(date, false, true); // 10. January at 10:20
    }

    return this.getFormattedDate(date); // 10. January 2017. at 10:20
  }

  static getFormattedDate(date, prefomattedDate = false, hideYear = false) {
    const MONTH_NAMES = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    // const hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${ minutes }`;
    }

    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${ prefomattedDate } at ${this.formatAMPM(date)}`;
    }

    if (hideYear) {
      // 10. January at 10:20
      return `${ day }. ${ month } at ${this.formatAMPM(date)}`;
    }

    // 10. January 2017. at 10:20
    return `${ day }. ${ month } ${ year }. at ${this.formatAMPM(date)}`;
  }

}
