<template>
  <div class="mt-3 mb-3 botter-intro-form-card pa-0">
    <div class="botter-continue d-flex align-center justify-space-between" @click="continueConversation">

      <div class="d-flex align-center">
        <div class="botter-agent-avatar d-flex">
          <img :src="this.$store.state.core.widgetParameters.botAvatar" height="35"  alt="">
        </div>
        <div class="ml-2 mr-2">
          <h5>{{ $t('Continue Conversation') }}</h5>
          <div class="botter-agent-status d-flex align-center">
            <div class="botter-online-indecator" >
              <span></span>
            </div>
            <p>{{lastMessageDate}}</p>
          </div>
        </div>
      </div>

      <span class="mdi mdi-chevron-right"></span>

    </div>
  </div>
</template>

<script>
  import common from "../utils/common";
  export default {
    name: "continueConversation",
    data(){
      return {
        lastMessageDate: ''
      }
    },
    mounted() {
      let date = localStorage.getItem('lastMessageDate')
      this.lastMessageDate = common.timeAgo(date)
      setInterval(()=>{
        this.lastMessageDate = common.timeAgo(date)
      },12000)
    },
    methods: {
      continueConversation() {
        this.$emit('continueConversation')
      }
    }
  }
</script>

<style scoped lang="scss">
.botter-continue {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #eee;
  padding: 10px;
  span {
    color: var(--primaryColor);
    font-size: 20px;
  }
  &:hover{
    background: #eee;
  }
  .botter-agent-avatar {
    border-radius: 100%;
    padding: 5px;
    img {
      width: 28px;
      height: 28px;
    }
  }
}

</style>
