import store from '../../store'

export default class WeRTCBrokerInterceptor {
    onCallInitiated() {
        // console.log('onCallInitiated', sessionId, interactionId, mediaConstraints)
    }

    onCallAccepted(sessionId) {
        console.warn('[WeRTCBrokerInterceptor]::Call accepted::', sessionId);
        store.dispatch('cxi_media_time_started', true)
        store.dispatch('cxi_loading', false)
    }

    onCallRejected(sessionId, rejectedReason) {
        console.warn('[WeRTCBrokerInterceptor]::Call rejected::', sessionId);
        store.dispatch('cxi_media_time_started', false)
        store.dispatch('cxi_loading', false)
        store.dispatch('cxi_message', {
            type: 'error',
            message: rejectedReason
        })
        store.dispatch('cxi_end_call')
    }
    
    onSessionClosed(sessionId) {
        console.warn('[WeRTCBrokerInterceptor]::onSessionClosed::', sessionId);
        store.dispatch('cxi_end_call')
    }
    
    mediaSessionStarted(interactionId){
        console.log('mediaSessionStarted', interactionId);

        setTimeout(() => {
            console.log('mediaSessionStarted', interactionId);
        }, 5000)
    }

}
