<template>
  <div>
    <template v-if="['wav', 'mp3', 'weba'].includes(getFileExtension(con.fileName))">
      <audio-data :con="con" :index="index" />
    </template>
    <template v-else>
      <div class="text botter-conversation">
        <div class="d-flex align-end" :class="getAlignment()">
          <user-chat-image :sender="con.sender" v-if="con.type === 'bot' && lastMessageGroup"/>
          <a  class="botter-attachment text-center" :href="con.mediaUrl || con.url" target="_blank">
            <v-icon>mdi-file-check-outline</v-icon>
            <p>{{con.fileName}}</p>
          </a>
        </div>
        <div v-if="lastMessageGroup" class="chat-date d-flex" :class="getAlignment()">{{common.formatDate(con.date)}}</div>
      </div>
    </template>
    
  </div>
</template>

<script>
  import userChatImage from "./common/userChatImage";
  import common from "../../utils/common";
  import AudioData from './audioData.vue';
  export default {
    name: "conversation",
    components: {
      userChatImage,
        AudioData
    },
    props: {
      con: {
        type: Object,
        default: ()=>{
          return {}
        }
      },
      index: {
        type: Number,
        default: ()=> {
          return 0
        }
      }
    },
    data() {
      return {
        common
      }
    },
    methods: {
      getAlignment() {
        if (this.con.type==='user') {
          return 'justify-end'
        } else if(this.con.type==='bot') {
          return 'justify-start'
        }
      },
      getColor() {
        if (this.con.type==='user') {
          return 'botter-user-text'
        } else if(this.con.type==='bot') {
          return 'botter-bot-text'
        }
      },
      getFileExtension(filename) {
        return filename.split('.').pop()
      }
    },
    computed: {
      lastMessageGroup () {
        return this.$store.state.messages.conversationList[this.index].lastMessageGroup
      }
    }
  }
</script>

<style lang="scss">
.botter-attachment {
  margin-top: 10px;
  background: #eaf0f7;
  width: 100%;
  border-radius: 5px;
  padding: 30px;
  .v-icon {
    font-size: 30px;
  }
}
</style>
