// import { LoggerFactory } from 'cxi-message-broker-client/logger/LoggerFactory';
// import { ClientUtils } from 'cxi-message-broker-client/utils/ClientUtils';
import store from '../../store'
const DownloadURL = process.env.VUE_APP_CXI_DOWNLOAD
export default class BrokerInterceptorImplementation {
    $eventBus = {}
    constructor(signallingClient, eventBus) {
        this.$eventBus = eventBus
        console.log('singalling constructor')
        this.onConnect = (connection) => {
            this.log('connection', connection)
            // store.dispatch('cxi_ready')
            // this.$eventBus.$emit('cxi.webrtc.ready')
        };
        this.signallingClient = signallingClient;
        // this.logger = LoggerFactory.getLogger(this.signallingClient.getSignalingConnection());

        this.$eventBus.$on('cxi.webrtc.end_call', (message) => {
            this._sendEvent({
                message: message,
                event_type: 'error'
            })
        })
    }

    log(...msgs){
        console.log('======================================================')
        msgs.forEach(msg => {
            console.log(`%c${JSON.stringify(msg, null, 4)}`, 'background: #212F3D ; color: #F9E79F;padding: 4px;border-radius: 4px;margin-bottom:2px');
        })
        console.log('======================================================')
    }

    escalateToAgent(sessionId, customerPayload) {
        this.log('escalateToAgent', sessionId, customerPayload)
    }
    onSessionTransferOffered(data) {
        this.log('onSessionTransferOffered', data)
    }
    onSessionTransferAccepted(sessionId, agentId) {
        this.log('onSessionTransferAccepted', sessionId, agentId)
    }
    onSessionTransferFailed(sessionId, failedReason) {
        this.log('onSessionTransferFailed', sessionId, failedReason)
    }
    onSessionTransferCompleate(sessionId, transferTo, transferToDetails) {
        this.log('onSessionTransferCompleate', sessionId, transferTo, transferToDetails)
    }
    onIPAddressReceive(ipaddress) {
        this.log('onIPAddressReceive', ipaddress)
        this.log(this.signallingClient.type)
        store.dispatch('cxi_ready', ipaddress)
        this.$eventBus.$emit('cxi.webrtc.ready')
    }
    onError(connection) {
        this.log('onError: ', connection);
    }
    onDisconnect(event) {
        this.log('onDisconnect: ', event);
    }

    onRegisterationSuccessfull(registerationKey, interactionId, sessionId, saveInGarageOnly) {
        this.$eventBus.$emit('cxi.webrtc.events', {type: 'escalation_initiated'})
        let session_data = store.state.cxi.chat_session_info
        store.dispatch('cxi_session_info', {...session_data, interactionId, sessionId})
        this.signallingClient.setSessionId(interactionId, sessionId);
        this.signallingClient.updateClientId(interactionId);
        this.$eventBus.$emit('cxi.webrtc.register', {
            registerationKey, interactionId, sessionId
        })
        this.log('REGISTERATION_SUCCESS',  registerationKey ,interactionId, sessionId, saveInGarageOnly)
    }

    onRegisterationFail(interactionId, reason) {
        this.$eventBus.$emit('cxi.webrtc.events', {type: 'escalation_failed', message: reason})
        this._sendEvent({
            message: reason,
            event_type: 'error'
        })
        store.dispatch('cxi_end_chat')
        store.dispatch('cxi_loading', false)
        this.signallingClient.connectWebChat()
        this.log('REGISTERATION_FAIL', interactionId, reason)
    }
    
    onOfferReceive(interactionId, senderDetails) {
        this.log('onofferreceive: ', interactionId, senderDetails);
        store.dispatch('cxi_agent_info', senderDetails)
        try {
            // this.signallingClient.getSignalingConnection()
            //     .createAnswer(interactionId, ClientUtils.getSupportedMediaBroker(), interactionId);

            this.$eventBus.$emit('cxi.webrtc.events', {type: 'escalation_started'})
            let session_data = store.state.cxi.chat_session_info
            store.dispatch('cxi_session_info', {...session_data, accepted: true})

            store.dispatch('cxi_loading', false)
        }
        catch (error) {
            console.error('onOfferReceive error: ', error);
        }
    }
    onOfferRejected(interactionId, rejectedReason) {
        this.$eventBus.$emit('cxi.webrtc.events', {type: 'escalation_rejected', message: rejectedReason})
        this.$eventBus.$emit('cxi.webrtc.connected', false)
        store.dispatch('cxi_end_chat')
        store.dispatch('cxi_loading', false)
        this.log('onOfferRejected', interactionId, rejectedReason)

        this._sendEvent({
            message: rejectedReason,
            event_type: 'error'
        })
    }
    onAnswerReceive(interactionId, sessionId, supportedBrokerType, brokerConf) {
        this.log('onAnswerReceive: ', supportedBrokerType, brokerConf);
        try {
            this.signallingClient.setSessionId(interactionId, sessionId);
        }
        catch (error) {
            console.error('onAnswerReceive error: ', error);
        }
    }
    onMessageReceive(message) {
        this.log('onMessageReceive: ', message);
        let msg = message.message
        // if(msg.senderType == 'Agent'){
            if(msg.type == 'text' || msg.type == 'file' || msg.type == 'audio'){
                this.$eventBus.$emit('cxi.webrtc.agent_message', msg.chatMessage)
                if(store.state.messages.chatActive){
					store.commit('cxi_reset_unread_messages')
				}else{
					store.commit('cxi_add_unread_messages')
					console.log('cxi_add_unread_messages')
				}
                
                this._pushMessage(msg)
            }else{
                this.$eventBus.$emit('cxi.webrtc.event_message', msg.chatMessage)
                this._sendEvent({
                    message: msg.chatMessage,
                    event_type: 'info'
                })
            }
        // }
    }
    onSeakMessage(message) {
        // this.log('onSeakMessage: ', message);
        store.dispatch('pushMessage', {
            slug: 'typing',
            type: 'bot',
            text: message,
            duration: 3000,
            lastMessageGroup:  true
        })
    }
    onClientSessionJoin(sessionId, clientDetails) {
        this.$eventBus.$emit('cxi.webrtc.connected', true)
        store.dispatch('cxi_agent_online', true)
        let eventMsg = `${clientDetails.name} joind`
        this._sendEvent({
            message: eventMsg,
            event_type: 'success'
        })
        this.log('onClientSessionJoin: ', clientDetails);
    }
    onClientSessionLeave(sessionId, clientId) {
        store.dispatch('cxi_agent_online', false)
        this.log('onClientSessionLeave', sessionId, clientId)
        this._sendEvent({
            message: 'The agent left chat',
            event_type: 'error'
        })
    }
    onSessionClose(endChat) {
        this.log('onSessionClose: ', endChat);
        try {
            // window.sessionStorage.removeItem('cxi.session')
            this.$eventBus.$emit('cxi.webrtc.events', {type: 'escalation_completed', message: endChat.endedReason})
            
            let session_data = store.state.cxi.chat_session_info
            if(session_data.payload && session_data.payload.blocksFinishing){
                session_data.payload.blocksFinishing.forEach(id => {
                    this.$eventBus.$emit('botter.blocks', {
                        title: 'End chat with agent',
                        block_id: id
                    })
                })
            }
            this.$eventBus.$emit('cxi.webrtc.end', true)
            setTimeout(() => {
                store.dispatch('cxi_end_chat')
                this.syncMessages = false
    
                this._sendEvent({
                    message: endChat.endedReason,
                    event_type: 'error'
                })
    
                // this.$eventBus.$off([
                //     'cxi.webrtc.events',
                //     'cxi.webrtc.message',
                //     'cxi.webrtc.event_message',
                //     'cxi.webrtc.agent_message',
                //     'cxi.webrtc.sendSneakMessage',
                //     'cxi.webrtc.file',
                //     'cxi.webrtc.end_call',
                // ])
            }, 100)
             
            // this.signallingClient.endChat()
        }
        catch (error) {
            console.error('onSessionClose error: ', error);
        }
    }
    onSessionSync(message) {
        this.lastMessage = message
        let session_data = store.state.cxi.chat_session_info
        if(!session_data.welcomeSync){
            session_data.welcomeSync = true
            if(store.state.cxi.text && (this.lastMessage && this.lastMessage != message) || !this.lastMessage){
                message.chatTranscript.reverse().forEach(msg => {
                    this._pushMessage(msg)
                })
            }
            store.dispatch('cxi_session_info', session_data)
        }
        
        // this.log('onSessionSync: ', message);
    }
    ack(messageId, responseMessage) {
        this.log('ack: ', {messageId, responseMessage});
    }
    _sendEvent(msg){
        if(this.lastEvent && this.lastEvent != msg.message || !this.lastEvent){
            this._pushMessage({
                type: 'event',
                event_type: msg.event_type,
                chatMessage: msg.message,
                senderType: 'system',
            })
            this.lastEvent = msg.message
        }
    }
    _pushMessage(msg){
        let data = {
            slug: msg.type,
            text: msg.chatMessage ,
            type: msg.senderType == 'Customer' ? 'user' : 'agent',
            lastMessageGroup:  true,
            date: msg.sentDateTime || new Date,
            senderName: msg.senderName || msg.senderType || 'System'
        }
        if(data.slug == 'file'){
            let fileExt = msg.fileName.split('.').pop()
            let imagesExt = ['jpg', 'jpeg', 'png', 'gif']
            data.slug =  imagesExt.includes(fileExt) ? 'image' : 'attachment'
            data.fileName = msg.fileName
            data.mediaUrl = `${DownloadURL}/${msg.fileName}`
        }

        if(data.slug == 'audio'){
            let fileExt = msg.fileName.split('.').pop()
            let audioExt = ['mp3','wav', 'weba']
            data.slug =  audioExt.includes(fileExt) ? 'audio' : 'attachment'
            data.fileName = msg.fileName
            data.mediaUrl = `${DownloadURL}/${msg.fileName}`
        }

        if(data.slug == 'event'){
            data.event_type = msg.event_type
        }

        store.dispatch('pushMessage', data)
    }
}