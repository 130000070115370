<template>
    <div class="botter--voice-recorder-wrap">
        <div v-if="!isFinished" class="botter--voice-recorder">
            <button type="button" class="me-4" @click="cancelRecorder">
               <svg xmlns="http://www.w3.org/2000/svg" style="width:24px" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
            </button>
            <div class="botter--recording-time">
                <span class="botter--recording-point"></span> <span>{{ timeDuration }}</span>
            </div>
            <button type="button" @click="stopRecording">
                <i class="mdi mdi-send"></i>
            </button>
        </div>
        <audio v-else ref="player" controls></audio>
    </div>
</template>
<script>
// import Recorder from 'recorder-js';
import MicRecorder from 'mic-recorder-to-mp3';

export default {
    name: 'recordVoice',
    components: {

    },
    beforeMount() {
        const audioContext =  new (window.AudioContext || window.webkitAudioContext)();
        this.recorder = new MicRecorder({
            bitRate: 128
        });
        // this.recorder = new Recorder(audioContext, {
        //   // onAnalysed: data => console.log(data)
        //   // An array of 255 Numbers
        //   // You can use this to visualize the audio stream
        //   // If you use react, check out react-wave-stream
        //   // onAnalysed: data => console.log(data),
        // });
        navigator.mediaDevices.getUserMedia({audio: true})
          .then(stream => null
          //this.recorder.init(stream)
          )
          .catch(err => console.log('Uh oh... unable to get stream...', err));
    },
    data() {
        return {
            isRecording: false,
            isFinished: false,
            blob: '',
            timerInstance: '',
            timeDuration: '',
            recorder: '',
            timeStartedCounter: 0
        }
    },
    mounted(){
        setTimeout(() => {
            this.startRecording()
        }, 400);
    },
    methods: {
        startRecording() {
            this.recorder.start().then(() => {
                this.isRecording = true
                this.startTimeDuration()

            });
        },
        stopRecording(eventType) {
            this.reset()
            this.isFinished = true

            this.recorder.stop().getMp3().then(([buffer, blob]) => {
                // do what ever you want with buffer and blob
                // Example: Create a mp3 file and play
                
                const file = new File(buffer, `voice-note-${Date.now()}.mp3`, {
                    type: blob.type,
                    lastModified: Date.now()
                });
                this.$emit('record', file)
                
                // const player = new Audio(URL.createObjectURL(file));
                // player.play();
            
            }).catch((e) => {
                // alert('We could not retrieve your message');
                console.log(e);
            });
            // this.recorder.stop().then(({blob, buffer}) => {
            //     URL = window.URL || window.webkitURL;
            //     this.$refs.player.src = URL.createObjectURL(blob)
            //     // this.blob = blob;
            //     this.$emit('record', blob)
            //     // buffer is an AudioBuffer
            // });
        },
        cancelRecorder(){
            this.reset()
            this.recorder.stop().then(({blob, buffer}) => {
                this.$emit('cancel')
            });
        },
        reset(){
            clearInterval(this.timerInstance)
            this.timeDuration = ''
            this.timeStartedCounter = 0
            this.isRecording = false
        },
        startTimeDuration(){
			this.timerInstance = setInterval(() => {
				++this.timeStartedCounter
				var hour = Math.floor(this.timeStartedCounter /3600);
				var minute = Math.floor((this.timeStartedCounter - hour*3600)/60);
				var seconds = this.timeStartedCounter - (hour*3600 + minute*60);
				if(hour < 10)
					hour = "0"+hour;
				if(minute < 10)
					minute = "0"+minute;
				if(seconds < 10)
					seconds = "0"+seconds;

				this.timeDuration = hour + ":" + minute + ":" + seconds
			}, 1000)
		}
    }
}
</script>
<style lang="scss">
    .botter--voice-recorder-wrap{
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        .botter--voice-recorder{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            .botter--recording-time{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                        justify-content: space-between;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                font-size: 14px;
                color: rgba(0, 0, 0, .8);
                
                .botter--recording-point{
                    width: 8px;
                    height: 8px;
                    background: #e62e2e;
                    border-radius: 100%;
                    display: inline-block;
                    margin: 6px;
                    -webkit-animation: flashing 1s linear 3;
                            animation: flashing 1s linear 3;
                }
            }
            button{
                height: 32px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                color: #e62e2e;
                .mdi-send{
                    color: var(--primaryColor);
                }
            }
        }
    }
    @-webkit-keyframes flashing {
        50% {
            opacity: 0;
        }
    }
    @keyframes flashing {
        50% {
            opacity: 0;
        }
    }
</style>