import Vue from 'vue'
import Vuex from 'vuex'
import {messages} from './modules/messages'
import {core} from './modules/core'
import {cxi} from './modules/cxi'


Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    messages,
    core,
    cxi
  },
})

export default store
