<template>
  <div class="chat-text" v-if="con.payload || con.text">
    <div v-html="con.payload || con.text" :class="`${con.event_type}-color`"></div>
    <!-- <div v-if="lastMessageGroup" class="chat-date" :class="getAlignment()">{{common.formatDate(con.date)}}</div> -->
  </div>
</template>

<script>
import userChatImage from "./common/userChatImage";
import common from "../../utils/common";
  export default {
    name: "textData",
    components: {
      userChatImage
    },
    props: {
      con: {
        type: Object,
        default: ()=>{
          return {}
        }
      },
      index: {
        type: Number,
        default: ()=> {
          return 0
        }
      }
    },
    data() {
      return {
        common
      }
    },
    methods: {
      getAlignment() {
        if (this.con.type==='user') {
          return 'justify-end'
        } else if(this.con.type==='bot') {
          return 'justify-start'
        }
      },
      getColor() {
        if (this.con.type==='user') {
          return 'botter-user-text'
        } else if(this.con.type==='bot') {
          return 'botter-bot-text'
        }
      }
    },
    computed: {
      lastMessageGroup () {
        return this.$store.state.messages.conversationList[this.index].lastMessageGroup
      }
    },
    watch: {
      'con.payload': function () {
        let arabic = /[\u0600-\u06FF]/
        let input = document.getElementsByClassName('botter-text')
        for (let i=0; i<input.length;i++) {
          if (arabic.test(this.userInput)) {
            input.style.direction = 'rtl'
          } else  {
            input.style.direction = 'ltr'
          }
        }

      }
    }
  }
</script>

<style lang="scss">
    .chat-text {
        text-align: center;
		margin-top: 10px;
        div {
			// background: #f8f8f9;
			color: #7B8388;
			padding: 1px;
			border-radius: 100px;
			min-width: 70%;
			display: inline-block;
			margin: 0;
			font-size: 12px;
			line-height: 16px;
			&.error-color{
				color: red;
			}
			&.info-color{
				color: skyblue;
			}
        }
    }
</style>
