<template>
    <div class="botter--widget" :class="{'botter-cxi--active': $store.state.cxi.video || $store.state.cxi.audio}">
        <div class="botter-modal">
            <transition name="fade">
                <div class="botter-modal-wrapper" key="1" v-if="sessionActive">
                    <div class="botter-modal-head">
                        <div class="botters-agents-list">
                            <div class="align-center d-flex justify-space-between">
                                <template v-if="!$store.state.cxi.text">
                                    <div class="botter-back-button" @click="backToIntro" v-if="!this.$store.state.core.widgetParameters.hideFaqs || !this.$store.state.core.widgetParameters.hideForms">
                                        <span class="mdi mdi-chevron-left"></span>
                                    </div>
                                    <div @click="openModal" v-if="channel === 'mobile'">
                                        <img height="25" src="https://uploads.prod.botter.live/uploads/2021/2//1615123709893_chevron-thin-left.png" class="mt-1" alt="">
                                    </div>
                                </template>
                                <div class="ml-2 mr-2 flex-grow">
                                    <h5>{{this.$store.state.core.widgetParameters.botName}}</h5>
                                </div>

                                <div class="botter-call-actions">
                                    <template v-if="$store.state.cxi.text && !$store.state.cxi.media_time_started">
                                        <button type="button" class="me-4" @click="end_cxi_chat" title="Exit">
                                            <i class="mdi mdi-logout"></i>
                                        </button>
                                        <template v-if="$store.state.cxi.agent_online">
                                            <button type="button" class="me-4" @click="cxi_call('video')" title="Video">
                                                <i class="mdi mdi-video"></i>
                                            </button>
                                            <button type="button" class="me-10" @click="cxi_call('audio')" title="Audio">
                                                <i class="mdi mdi-phone"></i>
                                            </button>
                                        </template>
                                    </template>
                                
                                    <div class="botter-close-desktop"  @click="openModal"  v-if="channel !== 'mobile'">
                                        <img src="https://api.botter.live/uploads/2020-07-24/1595585747.png" alt="">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <widget-body
                      :session-active="sessionActive"
                      :modal-active = "modalActive"
                      :on-focus = "modalOnFocus" />
                </div>

                <div class="botter-intro" key="2" v-else-if="!this.$store.state.core.widgetParameters.hideFaqs || !this.$store.state.core.widgetParameters.hideForms">
                    <div class="botter-intro-header">
                        <h3 class="botter-intro-title">{{$store.state.core.widgetParameters.header}}</h3>
                        <p>{{$store.state.core.widgetParameters.subHeader}}</p>
                    </div>
                    <div class="botter-close-mobile" @click="openModal" :class="{'botter-close-desktop': $store.state.core.widgetParameters.hideOpenButton}">
                        <img src="https://api.botter.live/uploads/2020-07-24/1595585747.png" alt="">
                    </div>
                    <div class="botter-intro-forms" :class="{'extra-padding': !common.getCookie('botkit_guid')}">
                        <div class="intro-wrapper">
                            <continue-conversation v-if="common.getCookie('botkit_guid')" @continueConversation="continueSession" />
                            <intro-form @start-chat="startSession"></intro-form>
                            <faqs v-if="!this.$store.state.core.widgetParameters.hideFaqs"></faqs>
                            <social-links/>
                        </div>
                    </div>

                </div>
            </transition>
        </div>

        <div v-if="!botterStarted && !$store.state.core.widgetParameters.hideOpenButton">
            <offline-message/>
        </div>

        <div class="botter-modal-toggle-btn" @click="openModal" v-show="!$store.state.core.widgetParameters.hideOpenButton">
            <img class="botter-modal-open-btn" :src="$store.state.core.widgetParameters.openBubbleImage" alt="">
            <img class="botter-modal-close-btn" :src="$store.state.core.widgetParameters.closeBubbleImage" alt="">
        </div>

        <!--  ====================================================================== -->
        <!--  Cxi Media (video / audio call) -->
        <!--  ====================================================================== -->
            <cxi-media-call v-if="cxi_webrtc_client" :client="cxi_webrtc_client" />
            <cxi-escalation />
        <!--  ====================================================================== -->

        <div class="cxi-cobrowse-modal" ref="cxi_modal" v-if="cxi_cobrowse_modal">
            <div class="modal-content">
                <span class="close" @click="cxi_cobrowse_accept_action(false)">&times;</span>
                <p class="pb-1" style="font-size: 16px !important">The agent is requesting a co-browse session</p>
                <p class="pb-4">Do you accept?</p>
                <div class="d-flex justify-end">
                    <button class="ms-3 botter-btn bg-primary" @click="cxi_cobrowse_accept_action(true)">Yes</button>
                    <button class="ms-3 botter-btn" @click="cxi_cobrowse_accept_action(false)">No</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import widgetBody from "./widgetBody";
    import botterLogo from "./UIElements/botterLogo";
    import introForm from './intro-forms/intro-forms'
    import faqs from "./faqs/faqs";
    import socialLinks from "./socialLinks";
    import offlineMessage from "./offlineMessage";
    import common from "../utils/common";
    import widgetParameters from "../utils/widgetParameters";
    import continueConversation from "./continueConversation";

    import cxiMediaCall from './CXI/mediaCall.vue';
    import cxiEscalation from './CXI/escalation.vue';
    
    export default {
        name: "botter-widget",
        data() {
            return {
                widgetParameters: {},
                sessionActive: false,
                modalActive: false,
                modalOnFocus: false,
                botterStarted: false,
                bodId: '',
                common,
                channel: '',
                cxi_webrtc_client: false,
                cxi_cobrowse_modal: false,
                cxi_cobrowse_data: {}
            }
        },
        components: {
            widgetBody,
            introForm,
            faqs,
            offlineMessage,
            socialLinks,
            continueConversation,
            cxiMediaCall,
            cxiEscalation
        },
        mounted() {
            const self = this
            const urlParams = new URLSearchParams(window.location.search);
            const botId = urlParams.get('bot_id') ? urlParams.get('bot_id') : document.getElementById('botter-widget').getAttribute('bot_id')
            this.$store.dispatch('setBotId',botId)

            this.widgetParameters = widgetParameters.getWidgetParameters()

            window.addEventListener('click', (e) => {   
              if (document.querySelector('.botter-modal').contains(e.target)){
                this.modalOnFocus = true
              } else{
                this.modalOnFocus = false
              }
            });

            this.changeState()
            this.openWidgetExternal()

            this.channel = urlParams.get('channel');
            if (this.channel  === 'mobile') {
                this.openModal()
            }
            //=========[ CXI ]==================================
            this.$eventBus.$on('cxi.webrtc.instance', (obj) => {
                self.cxi_webrtc_client = obj
            })

            this.$eventBus.$on('cxi.cobrowse.events', (obj) => {
                switch (obj.event) {
                    case 'OFFER_RECEIVED':
                        self.cxi_cobrowse_modal = true
                        self.cxi_cobrowse_data = obj.data
                        // console.log('cxi.cobrowse.events', obj)
                        break;
                    case 'ANSWER_RECEIVED':
                        break;
                }
            })

            //close modal on click outside
            window.onclick = function(event) {
                if (event.target == self.$refs.cxi_modal) {
                    self.cxi_cobrowse_modal = false
                }
            }
            //================================================
        },
        methods: {
            cxi_call(type){
                this.$store.dispatch('cxi_channel', type)
            },
            end_cxi_chat(){
                this.$eventBus.$emit('cxi.webrtc.end', false)
            },
            cxi_cobrowse_accept_action(value){
                // console.log('clicked on cxi_cobrowse_accept_action')
                this.$eventBus.$emit('cxi.cobrowse.accept', {
                    accepted: value,
                    data: this.cxi_cobrowse_data
                })
                this.cxi_cobrowse_modal = false
            },
            openModal() {
                window.parent.postMessage('toggleWidget', '*')
                if (this.modalActive) {
                    window.parent.postMessage('close', '*')
                    try {
                        webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify( {msg_code: 'Close'}));
                    }
                    catch (e) {
                        // alert(e.message)
                    }
                }

                setTimeout(()=>{
                    document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.toggle('botter-modal-btn-active')
                    document.getElementsByClassName('botter-modal')[0].classList.toggle('botter-modal-active')
                    this.modalActive = document.getElementsByClassName('botter-modal')[0].classList.contains('botter-modal-active')
                    this.$store.dispatch('setChatActive', this.modalActive)
                },100)

                if (!this.botterStarted) {
                    let formInitiated =common.getCookie('formInitiated')
                    if ((this.$store.state.core.widgetParameters.hideFaqs && this.$store.state.core.widgetParameters.hideForms) || formInitiated ) {
                        this.sessionActive = true
                    } else {
                        this.sessionActive = false
                    }
                    this.getFormData()
                }

                this.botterStarted = true
                // wait for the animation, then update focus
                setTimeout(()=> { this.modalOnFocus = this.modalActive }, 500)

            },
            startSession () {
                this.$store.dispatch('setStartNew', true)
                common.setCookie('formInitiated', true,1/24)
                let user = {}
                this.$store.state.core.attributes.forEach(v => {
                    user[v.attribute] = v.value
                })
                window.localStorage.setItem('botter.user', JSON.stringify(user))
                this.sessionActive = true
            },
            continueSession () {
                this.$store.dispatch('setStartNew', false)
                this.sessionActive = true
            },
            endSession () {
                common.delete_cookie('formInitiated')
                common.delete_cookie('botkit_guid')
                window.localStorage.removeItem('botter.user')
                this.$store.dispatch('setStartNew', true)
                this.botterStarted = false
                this.sessionActive = false
                this.modalActive = false
                this.$store.dispatch('setConversationList', [])
                document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.remove('botter-modal-btn-active')
                document.getElementsByClassName('botter-modal')[0].classList.remove('botter-modal-active')
            },
            backToIntro() {
                this.$store.dispatch('setConversationList', [])
                this.sessionActive = false
                this.$store.dispatch('setStartNew', false)
                localStorage.setItem('lastMessageDate', new Date())
            },
            getFormData () {
                this.$store.dispatch('setIntroDataLoader', true)
                this.$http.get(`widget/data/${this.$store.state.core.widgetParameters.lang}?bot_id=${this.$store.state.core.botId}`)
                  .then(response=> {
                        this.$store.dispatch('setMenus',response.data.menu)
                        this.$store.dispatch('setStartForm',response.data.startForm)
                        this.$store.dispatch('setEndForm',response.data.endForm)
                        this.$store.dispatch('setIntroDataLoader', false)
                    }
                  )
                  .catch(error=> {
                      this.$store.dispatch('setIntroDataLoader', false)
                  })
            },
            openWidgetExternal() {
                let currentIndex = -1
                let elements = document.getElementsByClassName(this.$store.state.core.widgetParameters.externalOpenClass)
                for (let i = 0; i < elements.length; i++) {
                    elements[i].addEventListener('click', ()=>{
                        let botId = elements[i].getAttribute('bot_id')
                        let externalAttributes = elements[i].getAttribute('botter-data')
                        if (currentIndex !== i) {
                            //this.endSession()
                        }
                        if (botId) {
                            this.$store.dispatch('setBotId', botId)
                        }
                        if (!common.isEmptyObj(externalAttributes)) {
                            externalAttributes = common.convertObjToArr(JSON.parse(externalAttributes))
                            this.$store.dispatch('setExternalAttributes', externalAttributes)
                        }
                        setTimeout(()=> {
                            this.openModal()
                        }, 200)

                        currentIndex = i
                    }, false);
                }
            },
            changeState () {
                if ( window.addEventListener ) {
                    window.addEventListener('message', (e)=> {
                        if (e.data.type === 'change-step') {
                            if(e.data.value === 'widget-offline') {
                                document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.remove('botter-modal-btn-active')
                                document.getElementsByClassName('botter-modal')[0].classList.remove('botter-modal-active')
                                this.botterStarted = false
                                this.sessionActive = false
                                this.modalActive = false

                            } else if (e.data.value === 'widget-intro') {
                                document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.add('botter-modal-btn-active')
                                document.getElementsByClassName('botter-modal')[0].classList.add('botter-modal-active')
                                this.botterStarted = true
                                this.sessionActive = false
                                this.modalActive = true
                                this.getFormData()
                            } else if (e.data.value === 'widget-chat') {
                                document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.add('botter-modal-btn-active')
                                document.getElementsByClassName('botter-modal')[0].classList.add('botter-modal-active')
                                this.botterStarted = true
                                this.sessionActive = true
                                this.modalActive = true
                            }
                         }
                     })
                    }
            }
        },
        computed: {
            connected() {
                return this.$store.state.messages.connected
            }
        },
        watch: {
            modalActive(val) {
                this.$store.dispatch('setChatActive', val)
                if(val){
                    this.$store.commit('cxi_reset_unread_messages')
                }
            }
        }
    }
</script>

<style lang="scss" src="../style/components/widgets.scss">
</style>
