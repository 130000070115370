<template>
  <div class="text botter-conversation user-input" v-if="con.payload">
    <div class="d-flex align-end " :class="getAlignment()">
      <user-chat-image :sender="con.sender" v-if="con.type === 'bot' && lastMessageGroup"/>
      <div  class="botter-text" :class="getColor()" v-if="con.payload">
        <p v-html="common.formatText(con.payload)" :class="common.getTextAlignment(con.payload)">
        </p>
        <user-input-field :data="con"/>
      </div>
    </div>
    <div v-if="lastMessageGroup" class="chat-date d-flex" :class="getAlignment()">{{common.formatDate(con.date)}}</div>
  </div>
</template>

<script>
  import userChatImage from "./common/userChatImage";
  import common from "../../utils/common";
  import userInputField from "./common/userInputField";
  export default {
    name: "textData",
    components: {
      userChatImage,
      userInputField
    },
    props: {
      con: {
        type: Object,
        default: ()=>{
          return {}
        }
      },
      index: {
        type: Number,
        default: ()=> {
          return 0
        }
      }
    },
    data() {
      return {
        common
      }
    },
    methods: {
      getAlignment() {
        if (this.con.type==='user') {
          return 'justify-end'
        } else if(this.con.type==='bot') {
          return 'justify-start'
        }
      },
      getColor() {
        if (this.con.type==='user') {
          return 'botter-user-text'
        } else if(this.con.type==='bot') {
          return 'botter-bot-text'
        }
      }
    },
    computed: {
      lastMessageGroup () {
        return this.$store.state.messages.conversationList[this.index].lastMessageGroup
      }
    }
  }
</script>

<style lang="scss">
  .user-input .botter-bot-text{
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.16) 3px 3px 10px;
    padding-top: 15px;
    border-top: 3px solid var(--primaryColor);
  }
</style>
