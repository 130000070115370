<template>
  <div>
    <v-style>
      <template v-if="widgetParameters.font">
       @import url('https://fonts.googleapis.com/css?family={{widgetParameters.font}}');
      </template>
     
      .botter--widget p , .botter--widget h1, .botter--widget h2, .botter--widget h3, .botter--widget h4, .botter--widget h5, .botter--widget h6, .botter--widget span,
      .botter--widget label, input
      {
      font-family: gotham_htfbook, tahoma !important;
      }
      #botter-widget .botter-user-text {
      background-color: {{widgetParameters.userSideColor}} !important;
      }
      #botter-widget .botter-user-text p {
      color: {{widgetParameters.userSideFontColor}} !important;
      }
      #botter-widget .botter-bot-text , #botter-widget .botter-gallery .botter-gallery-item , #botter-widget .botter-map-card {
      background-color: {{widgetParameters.chatBotAnswerColor}} !important;
      color: {{widgetParameters.chatBotAnswerFontColor}} !important;
      }
      #botter-widget .botter-bot-text  p , #botter-widget .botter-gallery-item{
      color: {{widgetParameters.chatBotAnswerFontColor}} !important;
      }
      #botter-widget .botter-intro .botter-intro-header , #botter-widget .botter-modal-head , #botter-widget .botter-intro .botter-intro-header h3 , #botter-widget .botter-intro .botter-intro-header p {

      color: {{widgetParameters.headerFontColor}};
      }
      #botter-widget .botter-intro .botter-intro-header , #botter-widget .botter-modal-head {
      background-color: {{widgetParameters.headerColor}} !important;
      }
      #botter-widget .botter-modal-toggle-btn {
      background-color: {{widgetParameters.bubbleButtonColor}} !important;
      }
      #botter-widget  .botter--widget p {
      font-size: {{widgetParameters.fontSize}}px !important;
      }

    </v-style>
    <v-style v-if="widgetParameters.position === 'left'">
      #botter-widget .botter-modal , #botter-widget .botter-modal-toggle-btn, #botter-widget .botter-offline-message {
        right: auto;
        left: 25px;
      }
      #botter-widget .botter-offline-message-icon {
        right: auto !important;
        left: 7px;
      }
    </v-style>
    <v-style v-if="widgetParameters.lang === 'ar'">
      #botter-widget .botter--widget {
        direction: rtl;
      }
      #botter-widget .botter-wdiget-body .botter-chat-action , #botter-widget .botter-modal-head , #botter-widget .botter-wdiget-body .botter-modal-chat{
        direction: ltr;
      }
      #botter-widget  .v-text-field--outlined .v-input__append-outer , #botter-widget .botter-continue .mdi{
      transform: rotate(180deg);
      }
      #botter-widget .botter-faq-search .v-input__slot {
      padding: 0 12px;
      border-radius: 0px 5px 5px 0px !important;
    }
      #botter-widget  .botter-intro .botter-close-mobile {
        left: 41px;
        right: auto !important
      }

    </v-style>
    <v-style v-if="widgetParameters.chatBubble === 'rounded'">

      #botter-widget .botter-bot-text {
          border-radius: 0px 20px 20px 20px !important;
      }
      #botter-widget .botter-user-text {
      border-radius: 20px 0 20px 20px !important;
      }
    </v-style>
    <v-style v-if="widgetParameters.chatBubble === 'curvey'">

      #botter-widget .botter-bot-text {
      border-radius: 0px 5px 5px 5px !important;
      }
      #botter-widget .botter-user-text {
      border-radius: 5px 0 5px 5px !important;
      }
    </v-style>
    <v-style v-if="widgetParameters.logoColorType === 'white'">
      #botter-widget .botter-intro-header img {
      filter: brightness(0) invert(1);
      }
    </v-style>
    <v-style v-if="widgetParameters.botAvatarColorType === 'white'">
      #botter-widget .botter-bot-chat-image img , #botter-widget .botter-offline-message-avatar img , #botter-widget .botter-intro-form-card .botter-agent-avatar img {
          -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
      }
    </v-style>
    <v-style v-if="widgetParameters.botAvatarColorType === 'colored'">
      #botter-widget .botter-bot-chat-image img{
      filter: none !important;
      }
    </v-style>
    <v-style v-if="widgetParameters.openBubbleImageColorType === 'colored'">
      #botter-widget .botter-modal-toggle-btn .botter-modal-open-btn {
      filter: none !important;
      }
    </v-style>
    <v-style>
       .botter-widget-body {
        background-image: url({{widgetParameters.demoBackground}});
        background-size: 100%;
      }
      .widget-iframe-wrapper .botter-widget-body {

      }
    </v-style>
    <v-style>
      #botter-widget .botter-bot-chat-image,
      #botter-widget .botter-offline-message .botter-offline-message-wrapper .botter-offline-message-avatar,
      #botter-widget .botter-intro-form-card .botter-agent-avatar
      {
        background-color: {{widgetParameters.avatarBackgroundColor}} !important;
      }
    </v-style>
  </div>


</template>

<script>
  import widgetParameters from "../utils/widgetParameters";

  export default {
    name: "dynamicCSS",
    data() {
      return {
        widgetParameters: {}
      }
    },
    mounted() {

      widgetParameters.getExternalApiData()
      let data = widgetParameters.getWidgetParameters()
      data.then(res=> {
        this.widgetParameters = res
        this.$vuetify.theme.themes.light.primary = this.widgetParameters.mainColor
        document.documentElement.style.setProperty("--primaryColor", this.widgetParameters.mainColor);
        this.$i18n.locale = this.$store.state.core.locale
      })




      if ( window.addEventListener ) {
        window.addEventListener('message', (e)=>{
          if (e.data.mainColor) {
            this.widgetParameters = e.data
            // console.log(e.data)
            this.$vuetify.theme.themes.light.primary = this.widgetParameters.mainColor
            document.documentElement.style.setProperty("--primaryColor", this.widgetParameters.mainColor);
            this.$store.dispatch('setWidgetParameters',e.data)
          }

        }, false);
      } else if ( window.attachEvent ) {
        window.attachEvent('onmessage', ()=>{
        });
      }

    }
  }
</script>

<style scoped>

</style>
